import { styled } from 'styled-components'

export const StyledNode = styled.div<{
	$level: number
	$isActive?: boolean
	$isExpanded: boolean
	$isLeafNode: boolean
}>`
	position: relative;
	display: flex;
	flex-direction: column;
	column-gap: 0.5rem;

	margin-left: 8px;
	cursor: pointer;
	border-left: ${({ $level, theme }) =>
		$level === 0 ? `none` : `1px solid ${theme.colours.primary.black}`};
	padding-left: ${({ $level, $isLeafNode }) =>
		!$isLeafNode ? ($level !== 0 ? '20' : '0') : '48'}px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: ${({ $isLeafNode }) => ($isLeafNode ? `2.4rem` : `0.8rem`)};
		height: 1px;
		margin-top: 19px;
		border-top-color: ${(props) =>
			props.$level === 0 ? `transparent` : props.theme.colours.primary.black};
		border-top-style: ${(props) => (props.$isExpanded ? `solid` : `dotted`)};
		border-top-width: 1px;
	}

	.single-item {
		font-size: 15px;
		line-height: 24px;
		padding: 0.5rem 0.7rem 0.5rem 0rem;
		display: flex;
		flex-direction: row;
		gap: 0.5rem;
		&:hover {
			color: ${(props) => props.theme.colours.primary.hardBlue};
			text-decoration: underline;
		}

		.code {
			font-weight: 600;
		}
		.term {
		}
	}

	.active {
		color: ${(props) => props.theme.colours.primary.hardBlue};
		font-weight: 400;
		text-decoration: underline;
	}

	.expand-button {
		border: 1px solid ${(props) => props.theme.colours.primary.black};
		background-color: ${(props) => props.theme.colours.primary.white};
		border-radius: 2px;
		width: 16px;
		height: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	a {
		color: ${(props) => props.theme.colours.primary.black};
		text-decoration: none;
	}
`
