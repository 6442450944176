import type { InputViewProps } from '@elastic/react-search-ui-views'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ClickOutsideContainer } from 'components/common'
import { useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { maxInputLength, paths } from 'utils'
import SearchOverlay from '../SearchOverlay'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface SearchInputOverlayProps {
	getInputProps: InputViewProps['getInputProps']
}

const SearchInputOverlay = ({ getInputProps }: SearchInputOverlayProps) => {
	const [showOverlay, setShowOverlay] = useState<boolean>(false)
	const inputRef = useRef<HTMLInputElement>(null)
	const { t } = useTranslation()

	const navigate = useNavigate()

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const searchTerm = inputRef.current?.value
		if (event.key === ' ' && searchTerm === '') {
			event.preventDefault()
		} else if (event.key === ' ' && searchTerm?.charAt(0) === ' ') {
			event.preventDefault()
		}
		if (event.key === 'Enter') {
			navigate(`${paths.result}/?q=${searchTerm}`)
		}
	}

	const inputProps = {
		placeholder: t('frontpage.search.placeholder'),
		'aria-label': t('frontpage.search.placeholder'),
	}

	return (
		<ClickOutsideContainer
			onClick={() => setShowOverlay(false)}
			className='w-100'
		>
			<Form.Control
				id='search-input'
				role='search'
				{...getInputProps(inputProps)}
				ref={inputRef}
				maxLength={maxInputLength}
				onKeyDown={handleKeyPress}
				onFocus={() => {
					setShowOverlay(true)
					inputRef.current?.scrollIntoView()
				}}
			/>
			<FontAwesomeIcon icon={faMagnifyingGlass} className='search-icon' />

			{showOverlay && <SearchOverlay />}
		</ClickOutsideContainer>
	)
}

export default SearchInputOverlay
