import { styled } from 'styled-components'
import { over } from 'utils'

const ElasticWrapper = styled.div<{ $isFrontpage?: boolean }>`
	background-color: transparent !important;
	padding: 0;
	position: relative;
	width: 100%;

	.sui-search-box {
		width: 100%;

		text-overflow: ellipsis;
		justify-content: flex-start;
		margin: ${(props) => (props.$isFrontpage ? '0 auto' : '0')};
		${over('lg')} {
			max-width: 800px;
		}
		.sui-search-box__text-input {
			transition: all 0.2s;
			outline: 1px solid ${(props) => props.theme.colours.primary.greyShade};
			border: none;
			font-size: 16px;
			padding: 0.8rem 1rem;

			&:focus,
			&:active {
				outline: 1px solid ${(props) => props.theme.colours.primary.blue};
				box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
			}
		}
		.search-icon {
			position: absolute !important;
			right: 16px;
			top: 16px;
			z-index: 1;
			color: ${(props) => props.theme.colours.primary.blue};
		}
	}

	.sui-paging {
		display: flex;
		justify-content: center;
		font-size: 16px;
		margin-bottom: 0;
		${over('lg')} {
			font-size: 18px;
		}
	}

	.sui-paging .rc-pagination-disabled a {
		color: ${(props) => props.theme.colours.primary.greyShade};
		opacity: 0.7;

		&:hover {
			background: ${(props) => props.theme.colours.primary.greyShade};
		}
	}

	.sui-paging .rc-pagination-item-active a {
		color: ${(props) => props.theme.colours.primary.white};
		background: ${(props) => props.theme.colours.primary.blue};
		padding: 0.3rem 0.5rem;
		border-radius: 3px;
	}

	.accordion-active {
		outline: 1px solid ${(props) => props.theme.colours.primary.blue};
		border-radius: 5px;
	}
	.sui-select {
		margin-top: 0;
	}

	.result-meta-bar {
		border-bottom: 1px solid ${(props) => props.theme.colours.primary.black};
		padding-bottom: 0.6rem;
	}

	em {
		background-color: ${(props) => props.theme.colours.primary.blueLighter};
		font-style: normal;
		font-weight: 600;
		border-radius: 3px;
		padding: 0.2rem 0;
		color: ${(props) => props.theme.colours.primary.black};
	}

	.nav-tabs {
		border: unset;
		border-width: unset;
		border-bottom: 1px solid ${(props) => props.theme.colours.primary.greyShade};
		&:hover {
			border-bottom: 1px solid
				${(props) => props.theme.colours.primary.greyShade};
		}
	}
`

export default ElasticWrapper
