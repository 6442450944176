import { useEffect, useState } from 'react'
import type { FacetViewProps } from '../interface'
import type { FacetValue, FieldValue } from '@elastic/search-ui'
import { arraysEqual, getFilterValueDisplay } from '../utils'
import { Checkbox, FilterWrapper } from '../style'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { Button, CustomAccordionToggle } from 'components/common'
import { Accordion, Card } from 'react-bootstrap'
import { FilterTitle } from '../base'
import { useStoreContext } from 'context/StoreProvider'

const CustomFilter = ({
	label,
	onRemove,
	onSelect,
	values,
	options,
	showSearch,
	searchPlaceholder,
	onSearch,
	sortAlphabetically = false,
	showDisabledOptions,
}: FacetViewProps) => {
	const cleanedOptions = options.filter(
		(o) => o.value !== 'Kodeverk i standarder'
	)
	const activeFilters = cleanedOptions.map((option) => option.value)
	const [checkedFilters, setCheckedFilters] = useState(values)
	const [tempCheckedFilters, setTempCheckedFilters] = useState<string[]>([])

	const { state } = useStoreContext()

	const { codeSystem } = state

	const [savedOptions, setSavedOptions] = useState<FacetValue[]>([])

	const optionsCount = cleanedOptions.map((option) => option.count)

	const optionsWithCount = cleanedOptions.map((option, index) => ({
		value: option.value,
		count: optionsCount[index],
	}))

	useEffect(() => {
		const option = JSON.parse(
			sessionStorage.getItem(`options_${label}`) || '[]'
		).filter((o: { value: string }) => o.value !== 'Kodeverk i standarder')

		if (cleanedOptions.length > option.length) {
			setSavedOptions(cleanedOptions)
			// Fjerner kodeverk i standarder i hovedsøk for nå og fjerner derfor også filteret
			sessionStorage.setItem(`options_${label}`, JSON.stringify(cleanedOptions))
		} else if (
			option &&
			option.length > 0 &&
			option.value !== 'Kodeverk i standarder'
		) {
			setSavedOptions(option)
		} else {
			setSavedOptions(cleanedOptions)
			sessionStorage.setItem(`options_${label}`, JSON.stringify(cleanedOptions))
		}
	}, [options, label])

	useEffect(() => {
		if (!arraysEqual(checkedFilters, values)) {
			setCheckedFilters(values)
		}
	}, [values])

	/* Denne avhuker et filter som blir disabled etter at bruker gjør et søk */
	useEffect(() => {
		checkedFilters.forEach((filter: FieldValue) => {
			const isDisabled = !activeFilters.includes(filter)
			if (isDisabled) {
				onRemove(filter)
			}
		})
	}, [activeFilters, checkedFilters, onRemove])

	const handleFilterChange = (option: string) => {
		const isDisabled = !activeFilters.includes(option)
		const checked = checkedFilters.includes(option)

		if (checked && isDisabled) {
			onRemove(option)
			setTempCheckedFilters(
				checkedFilters.filter((filter: FieldValue) => filter !== option)
			)
			setCheckedFilters(
				checkedFilters.filter((filter: FieldValue) => filter !== option)
			)
			return
		}

		if (checked) {
			onRemove(option)
			setCheckedFilters(
				checkedFilters.filter((filter: FieldValue) => filter !== option)
			)
		} else {
			onSelect(option)
			setCheckedFilters([...checkedFilters, option])
		}
	}

	useEffect(() => {
		const newCheckedFilters = tempCheckedFilters.filter((filter) => {
			const isDisabled = !activeFilters.includes(filter)
			if (isDisabled) {
				return false
			}
			return true
		})
		if (newCheckedFilters.length > 0) {
			setCheckedFilters([...checkedFilters, ...newCheckedFilters])
			setTempCheckedFilters([])
		}
	}, [activeFilters, checkedFilters, tempCheckedFilters])

	useEffect(() => {
		const newCheckedFilters = checkedFilters.filter((filter: FieldValue) => {
			const isDisabled = !activeFilters.includes(filter)
			if (isDisabled) {
				return false
			}
			return true
		})
		if (!arraysEqual(checkedFilters, newCheckedFilters)) {
			setCheckedFilters(newCheckedFilters)
		}
	}, [activeFilters, checkedFilters])

	const savedOptionsValues = savedOptions.map((option) => option.value)

	/* Sorterer alfabetisk */
	const sortedSavedOptionsValues = [...savedOptionsValues].sort((a, b) => {
		if (typeof a === 'string' && typeof b === 'string') {
			return a.localeCompare(b)
		} else if (typeof a === 'number' && typeof b === 'number') {
			return a - b
		} else {
			return 0
		}
	})

	const shouldSortAlphabetically = sortAlphabetically
		? sortedSavedOptionsValues
		: savedOptionsValues

	/* Sorterer disabled nederst */
	const sortedDisplayedOptions = [...shouldSortAlphabetically].sort((a, b) => {
		const aIsDisabled = !activeFilters.includes(a)
		const bIsDisabled = !activeFilters.includes(b)

		if (aIsDisabled && !bIsDisabled) {
			return 1
		}
		if (!aIsDisabled && bIsDisabled) {
			return -1
		}
		return 0
	})

	/* Funksjonalitet for å vise flere/færre alternativer
			- Dersom filteret allerede har mindre enn 10 alternativer viser den alle
			- Hvis flere, viser den kun 5 med knapp for å vise flere
	*/
	const [showMoreOptions, setShowMoreOptions] = useState(false)
	const maxResults = cleanedOptions.length > 11 ? 5 : 11
	const displayedOptions = showMoreOptions
		? sortedDisplayedOptions
		: sortedDisplayedOptions.slice(0, maxResults)

	const [displayedFilterCount, setDisplayedFilterCount] = useState(maxResults)

	const handleShowMoreOptions = () => {
		setShowMoreOptions(true)
		setDisplayedFilterCount(displayedFilterCount + maxResults)
	}

	const handleShowLessOptipns = () => {
		setShowMoreOptions(false)
		setDisplayedFilterCount(displayedFilterCount - maxResults)
	}

	return (
		<Accordion defaultActiveKey='0' flush>
			<FilterWrapper>
				<Card>
					<Card.Header>
						<CustomAccordionToggle eventKey='0' iconRight iconDownUp>
							<FilterTitle>{label}</FilterTitle>
						</CustomAccordionToggle>
					</Card.Header>
					<Accordion.Collapse eventKey='0'>
						<Card.Body>
							{showSearch ||
								(cleanedOptions.length > 4 && (
									<div className='sui-facet-search'>
										<input
											className='sui-facet-search__text-input'
											type='search'
											placeholder={searchPlaceholder || 'Søk'}
											onChange={(e) => {
												onSearch(e.target.value)
											}}
										/>
									</div>
								))}

							{displayedOptions.slice(0, displayedFilterCount).map((option) => {
								if (
									codeSystem === 'icpc2' &&
									!['ICPC-2', 'ICPC-2B'].includes(option as string)
								) {
									return null
								}

								const isDisabled = !activeFilters.includes(option)
								const checked = values.includes(option) && !isDisabled
								if (isDisabled && !showDisabledOptions) return null
								return (
									<div
										key={`facet_${label}${getFilterValueDisplay(option)}`}
										className='d-flex justify-content-between'
									>
										<Checkbox
											type='checkbox'
											label={option as string}
											id={`facet_${label}${getFilterValueDisplay(option)}`}
											disabled={isDisabled}
											checked={checked}
											onChange={() => handleFilterChange(option as string)}
											style={{ fontWeight: checked ? 'bold' : 'normal' }}
											aria-label={`${option}, antall treff: ${
												optionsWithCount.find(
													(optionCount) => optionCount.value === option
												)?.count ?? '0'
											}`}
										/>
										<span className='mt-1'>
											<span className='filter-count'>
												{optionsWithCount.find(
													(optionCount) => optionCount.value === option
												)?.count ?? '0'}
											</span>
										</span>
									</div>
								)
							})}
							{sortedDisplayedOptions.length > displayedFilterCount && (
								<span>
									<Button
										type='button'
										onClick={handleShowMoreOptions}
										icon={faChevronDown}
										className='pt-2 ps-0'
										color='blue'
										size='sm'
										aria-label='Vis flere alternativer'
									>
										Vis flere
									</Button>
								</span>
							)}
							{showMoreOptions && (
								<span>
									<Button
										type='button'
										onClick={handleShowLessOptipns}
										icon={faChevronUp}
										className='ps-0'
										color='blue'
										size='sm'
										aria-label='Vis færre alternativer'
									>
										Vis færre
									</Button>
								</span>
							)}
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</FilterWrapper>
		</Accordion>
	)
}
export default CustomFilter
