import { Col, Overlay, Popover } from 'react-bootstrap'
import Button from '../Button'
import {
	faBell,
	faFileDownload,
	faArrowsDownToLine,
	faNetworkWired,
	faBug,
	faInfoCircle,
	faFileCsv,
	faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons'
import { endpoints, getApiEndpoint, getCodeSystemEndpoint } from 'utils'
import { RefObject, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import optionsData from './utils/optionsData'
import { OptionButton } from './common'
import { isDesktop } from 'react-device-detect'

const Options: React.FC = () => {
	const { t } = useTranslation()
	const [showPopover, setShowPopover] = useState<boolean>(false)
	const { pathname } = useLocation()
	const [target, setTarget] = useState<HTMLElement | null>(null)
	const popoverRef = useRef<HTMLElement>(null)

	const handlePopoverClick = useCallback<
		React.MouseEventHandler<HTMLButtonElement>
	>(
		(event) => {
			setShowPopover(!showPopover)
			if (event.target) {
				setTarget(event.target as HTMLElement)
			}
		},
		[showPopover]
	)

	const handleOptionsData = (property: string) => {
		const data = pathname.includes('icd10')
			? optionsData.icd10
			: pathname.includes('icpc2')
			? optionsData.icpc2
			: pathname.includes('nkpk')
			? optionsData.nkpk
			: pathname.includes('phbu')
			? optionsData.phbu
			: pathname.includes('norpat')
			? optionsData.norpat
			: pathname.includes('anatomisk')
			? optionsData.anatomisk
			: pathname.includes('provemateriale')
			? optionsData.provemateriale
			: pathname.includes('tekstligeresultatverdier')
			? optionsData.tekstligeResultatverdier
			: pathname.includes('nlk')
			? optionsData.nlk
			: pathname.includes('undersokelsesmetode')
			? optionsData.undersokelsesmetode
			: pathname.includes('adm')
			? optionsData.adm
			: pathname.includes('snomed')
			? optionsData.snomedct
			: null

		return property ? data?.[property] : data
	}

	return (
		<Col xs='auto' ref={popoverRef} className='d-flex gap-2'>
			<Button
				size='md'
				icon={faEllipsisVertical}
				iconright
				onClick={handlePopoverClick}
				className='p-0 pe-0'
				ariaLabel={t('codesystems.options.showMoreOptions')}
			>
				{isDesktop &&
					window.innerWidth > 700 &&
					t('codesystems.options.showOptions')}
			</Button>
			<Overlay
				show={showPopover}
				target={target}
				placement='bottom-end'
				container={popoverRef.current?.parentNode as HTMLElement}
				containerPadding={20}
				rootClose
				rootCloseEvent='click'
				onHide={() => setShowPopover(false)}
			>
				<Popover
					id='popover-contained'
					ref={popoverRef as RefObject<HTMLDivElement>}
					className='p-0'
				>
					<Popover.Body className='px-1'>
						{handleOptionsData('about') && (
							<OptionButton
								icon={faInfoCircle}
								text={handleOptionsData('about')}
								onClick={() => {
									const url = handleOptionsData('aboutLink')
									if (typeof url === 'string') {
										window.open(url, '_blank')
									}
								}}
							/>
						)}

						{handleOptionsData('subsrcibe') && (
							<OptionButton
								icon={faBell}
								text={t('codesystems.options.subscribe')}
								onClick={() => {
									const url = handleOptionsData('subsrcibe')
									if (typeof url === 'string') {
										window.open(url, '_blank')
									}
								}}
							/>
						)}

						{handleOptionsData('downloadExcel') && (
							<OptionButton
								icon={faFileDownload}
								text={t('codesystems.options.downloadExcel')}
								onClick={() => {
									const url = handleOptionsData('downloadExcel')
									if (typeof url === 'string') {
										window.open(url, '_blank')
									}
								}}
							/>
						)}

						{handleOptionsData('hasExcelApiEndpoint') && (
							<OptionButton
								icon={faFileDownload}
								text={t('codesystems.options.downloadExcel')}
								onClick={() => {
									const url = getApiEndpoint(
										getCodeSystemEndpoint(pathname as string) as string,
										'',
										{
											download: true,
											fileType: 'EXCEL',
										}
									)
									if (typeof url === 'string') {
										window.open(url, '_blank')
									}
								}}
							/>
						)}

						{handleOptionsData('downloadCsv') && (
							<OptionButton
								icon={faFileCsv}
								text={t('codesystems.options.downloadCsv')}
								onClick={() => {
									const url = handleOptionsData('downloadCsv')
									if (typeof url === 'string') {
										window.open(url, '_blank')
									}
								}}
							/>
						)}

						{handleOptionsData('downloadXML') && (
							<OptionButton
								icon={faArrowsDownToLine}
								text={t('codesystems.options.downloadXML')}
								onClick={() => {
									const url = handleOptionsData('downloadXML')
									if (typeof url === 'string') {
										window.open(url, '_blank')
									}
								}}
							/>
						)}

						{handleOptionsData('api') && (
							<OptionButton
								icon={faNetworkWired}
								text={t('codesystems.options.getApi')}
								onClick={() => window.open(endpoints.apiUrlDev, '_blank')}
							/>
						)}

						{handleOptionsData('report') && (
							<OptionButton
								icon={faBug}
								text={t('codesystems.options.reportError')}
								onClick={() => {
									const url = handleOptionsData('report')
									if (typeof url === 'string') {
										window.open(url, '_blank')
									}
								}}
								hasExtraText
								extraText={handleOptionsData('reportEmail') as string}
							/>
						)}
					</Popover.Body>
				</Popover>
			</Overlay>
		</Col>
	)
}

export default Options
