import { Col } from 'react-bootstrap'
import { styled } from 'styled-components'
import { over } from 'utils'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;

	label {
		padding: 0;
	}
`

export const ScrollWrapper = styled.div<{ $isSidebar?: boolean }>`
	position: relative;
`

export const SidebarWrapper = styled(Col)<{ $topValue: string }>`
	position: relative;

	${over('lg')} {
		position: sticky;
		top: ${({ $topValue }) => `${$topValue}px`};
		height: calc(100vh - 180px);
	}
	${over('huge')} {
		overflow-x: hidden;
	}
`
