import { styled } from 'styled-components'

export const TableWrapper = styled.div<{ $topValue: string }>`
	position: relative;
	width: 100%;

	table {
		position: relative;
		border-collapse: collapse;
	}

	tr:nth-child(4n + 1) {
		background-color: #f2f6fa;
	}

	td {
		background-color: unset;
		padding: 0.7rem 0.6rem;
	}
	th {
		background-color: white;
		position: sticky !important;
		top: ${({ $topValue }) => `${$topValue}px`};
		border-bottom: 2px solid ${(props) => props.theme.colours.primary.blue};
		z-index: 1;
	}

	.active-border {
		border-top: 2px solid ${(props) => props.theme.colours.primary.blue} !important;
		border-right: 1px solid ${(props) => props.theme.colours.primary.blue};
		border-left: 1px solid ${(props) => props.theme.colours.primary.blue};
	}

	.inner-table {
		background-color: #f2f6fa;
	}

	.inner-table-active {
		border-bottom: 1px solid ${(props) => props.theme.colours.primary.blue};
		border-right: 1px solid ${(props) => props.theme.colours.primary.blue};
		border-left: 1px solid ${(props) => props.theme.colours.primary.blue};
	}
`
