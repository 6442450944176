import { HierarchyResponse } from './interface'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from 'context/StoreProvider'
import { UseQueryResult, useQueries, useQuery } from '@tanstack/react-query'
import Node from './Node'
import { HierarchyWrapper } from './style'
import {
	chapterString,
	codesystemsEndpointName,
	fetchApi,
	getApiEndpoint,
} from 'utils'
import { ErrorMessage, Loading } from 'components/common'
import { useTranslation } from 'react-i18next'

const Hierarchy = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const { state, actions } = useStoreContext()

	const { code, codeSystem, hierarchyScrollRef } = state
	const { updateCodeValue } = actions

	const [fetchError, setFetchError] = useState<string>('')

	const [expandedNodes, setExpandedNodes] = useState<string[]>([])

	/* Kan ikke bruke fetchApi, fordi hierarkiet har en ektra condition om å hente root dersom den får error */
	const fetchHierarchy = async () => {
		try {
			const res = await fetch(
				getApiEndpoint(codeSystem as string, 'root', {
					useHierarchy: true,
					includeInactive: codeSystem === codesystemsEndpointName.norpat,
				})
			)

			if (!res.ok && res.status !== 404) {
				setFetchError(res.statusText)
			}

			if (!res.ok && code === undefined) {
				// If the server responds with a 404 error, fetch the root instead
				const rootResponse = await fetch(
					getApiEndpoint(codeSystem as string, 'root', {
						useHierarchy: true,
						includeInactive: codeSystem === codesystemsEndpointName.norpat,
					})
				)
				return await rootResponse.json()
			} else {
				return await res.json()
			}
		} catch (err) {
			throw err
		}
	}
	const {
		data: hierarchy,
		isLoading,
		isError,
	} = useQuery<HierarchyResponse, Error>({
		queryKey: [`${codeSystem}-hierarchy`, code],
		queryFn: fetchHierarchy,
		retry: false,
	})

	/* Fetches the code to get the path outside the children */
	const { data: hierarchyChildren } = useQuery<HierarchyResponse, Error>({
		queryKey: [`${codeSystem}-children`, code],
		queryFn: () =>
			fetchApi(
				getApiEndpoint(codeSystem as string, code, {
					useChildren: true,
					includeInactive: codeSystem === codesystemsEndpointName.norpat,
				})
			),
		enabled: !!code,
		retry: false,
	})

	/* Sets the path of the code in question to the expanded nodes */
	useEffect(() => {
		if (hierarchyChildren?.path) {
			setExpandedNodes((prevExpandedNodes) => {
				// Create a new array that includes all the previous expanded nodes and the items in hierarchyChildren.path
				let newExpandedNodes = [...prevExpandedNodes, ...hierarchyChildren.path]

				// Remove duplicates
				newExpandedNodes = [...new Set(newExpandedNodes)]

				return newExpandedNodes
			})
		}
	}, [hierarchyChildren?.path])

	const hierarchyChildData = useQueries({
		queries: expandedNodes
			.map((codeValue) => ({
				queryKey: [`${code}-children`, codeValue],
				queryFn: () =>
					fetchApi(
						getApiEndpoint(codeSystem as string, codeValue, {
							useChildren: true,
							includeInactive: codeSystem === codesystemsEndpointName.norpat,
						})
					),
				retry: false,
				staleTime: Infinity,
			}))
			.map((queryResult, index) => ({
				...queryResult,
				codeValue: expandedNodes[index],
			})),
	})

	const handleNavigate = (item: HierarchyResponse) => () => {
		updateCodeValue(item.codeValue as string)
		navigate(`${chapterString}/${item.codeValue}/`)
	}

	const handleExpand = (item: HierarchyResponse) => () => {
		setExpandedNodes((prevExpandedNodes) => {
			if (prevExpandedNodes.includes(item.codeValue)) {
				// If the node is already expanded, remove it from expandedNodes
				return prevExpandedNodes.filter(
					(codeValue) => codeValue !== item.codeValue
				)
			} else {
				// If the node is not expanded, add it to expandedNodes
				const newExpandedNodes = [...prevExpandedNodes, item.codeValue]

				return newExpandedNodes
			}
		})
	}

	const renderItems = (items: HierarchyResponse[], level = 0) => {
		return (
			items &&
			items.map((item) => {
				return (
					<Fragment key={item.codeValue}>
						<Node
							item={item}
							level={level}
							isActive={code === item.codeValue}
							isExpanded={expandedNodes.includes(item.codeValue)}
							handleNavigate={handleNavigate(item)}
							handleExpand={handleExpand(item)}
							innerRef={(ref: HTMLDivElement | null) => {
								hierarchyScrollRef.current[item.codeValue] = ref
							}}
						>
							{expandedNodes.includes(item.codeValue) &&
								hierarchyChildData?.map(
									(queryResult: UseQueryResult<HierarchyResponse, Error>) => {
										const child = queryResult.data
										if (child?.codeValue === item.codeValue) {
											return renderItems(child.children, level + 1)
										}
									}
								)}
						</Node>
					</Fragment>
				)
			})
		)
	}

	return (
		<>
			{code ? (
				<button
					onClick={(e) => {
						e.preventDefault()
						const element = document.getElementById('chapter-content')
						if (element) {
							element.scrollIntoView()
							element.focus()
						}
					}}
					className='skip-link'
				>
					{t('accessibility.jumpToContent')}
				</button>
			) : null}

			<HierarchyWrapper id={`${codeSystem}-hierarchy-tree`}>
				{isLoading && <Loading />}
				{isError && <ErrorMessage errorResponse={fetchError} />}
				{renderItems(hierarchy?.children as HierarchyResponse[])}
			</HierarchyWrapper>
		</>
	)
}

export default Hierarchy
