import {
	Col,
	Row,
	Table,
	Toast as BSToast,
	Accordion,
	ListGroup,
} from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { endpoints, fetchApi, formatDate, getApiEndpoint } from 'utils'
import { CollectionResponse } from './interface'
import {
	CodeValuesItem,
	DownloadContainer,
	StyledToast,
	Wrapper,
	ScrollWrapper,
} from './style'
import { Heading, Tag, Loading, ErrorMessage, Button } from 'components/common'
import { getStatusText } from 'components/search/buckets/utils'
import {
	getTagColor,
	getToastColour,
	getToastText,
	sortCodeValues,
} from './utils'
import {
	faCheck,
	faClose,
	faDownload,
	faFileExcel,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import parse from 'html-react-parser'
import { useStoreContext } from 'context/StoreProvider'

const AdmResults = () => {
	const { t } = useTranslation()
	const { state } = useStoreContext()
	const { code } = state

	const [showInactiveCodes, setshowInactiveCodes] = useState<boolean>(false)

	const [fetchError, setFetchError] = useState<string>('')

	const [codeValues, setCodeValues] = useState<string[]>([])
	const [showToast, setShowToast] = useState<boolean>(false)
	const [toastStatus, setToastStatus] = useState<string>('' as string)

	const { data, isFetching, isError, refetch } = useQuery<
		CollectionResponse,
		Error
	>({
		queryKey: ['adm', code],
		queryFn: () =>
			fetchApi(
				getApiEndpoint('adm', code as string, {
					isAdm: true,
					includeInactive: true,
				}),
				setFetchError('404')
			),
		enabled: !!code,
		retry: false,
	})

	const hasInactiveCodeValues = data?.codeValues.map((item) => item.active)

	useEffect(() => {
		setshowInactiveCodes(false)
	}, [code])

	useEffect(() => {
		if (code) {
			refetch()
		}
	}, [showInactiveCodes])

	useEffect(() => {
		const codeValue = JSON.parse(localStorage.getItem('codeValue') || '[]')

		if (codeValue) {
			setCodeValues(codeValue)
		}
	}, [])

	const handleAddCodeValue = (codeValue: string) => {
		const newCodeValue = [...codeValues, codeValue]
		if (codeValues.includes(codeValue)) {
			setToastStatus('errorAlreadyExists')
		} else if (codeValues.length > 199) {
			setToastStatus('countError')
			setShowToast(true)
		} else {
			setToastStatus('successAddItem')
			setCodeValues(newCodeValue)
			setShowToast(true)
		}

		localStorage.setItem('codeValue', JSON.stringify(newCodeValue))
	}

	const handleRemoveCodeValue = (codeValue: string) => {
		const newCodeValue = codeValues.filter((i) => i !== codeValue)
		setCodeValues(newCodeValue)
		localStorage.setItem('codeValue', JSON.stringify(newCodeValue))
		setShowToast(true)

		setToastStatus('removed')
	}

	const clearCodeValues = () => {
		localStorage.removeItem('codeValue')
		setCodeValues([])
	}

	const handleRemoveAllCodeValues = () => {
		setShowToast(true)
		setToastStatus('removeAll')
		clearCodeValues()
	}

	const downloadUrl = `${
		endpoints.apiAdmEndpointDev
	}/${codeValues.toString()}/download/EXCEL`

	const handleDownloadAllCodeValues = () => {
		window.open(downloadUrl)
		clearCodeValues()
		setShowToast(true)
		setToastStatus('sucessDownloadAll')
	}

	return (
		<ScrollWrapper className='d-flex flex-column gap-4 p-0 pt-lg-4 px-lg-4'>
			{isFetching && <Loading />}
			{isError && <ErrorMessage errorResponse={fetchError} />}
			{data && (
				<Wrapper
					className='d-flex flex-column gap-4 p-0 position-relative justify-content-between'
					id='adm-content'
					as={'section'}
					tabIndex={0}
				>
					<article className='d-flex flex-column gap-4 p-0 position-relative justify-content-between'>
						{data.statusLastChanged && (
							<Row className='flex-row text-size-14 p-0 mt-4 mt-md-0 align-items-center justify-content-between'>
								<Col className='p-0'>
									{t('general.lastChanged')}{' '}
									{formatDate(data.statusLastChanged)}
								</Col>
								{data.status && (
									<Col className='p-0' xs='auto'>
										<Tag
											color={getTagColor(data.status)}
											text={getStatusText(data.status)}
										/>
									</Col>
								)}
							</Row>
						)}
						<Heading
							variant='h2'
							className='fs-3 d-flex'
							id='adm-results-heading'
							aria-label={`${data.name} med id ${data.id}`}
						>
							<span>
								{data?.name} (<span className='fw-bold'>{data.id}</span>)
							</span>
						</Heading>
						<hr />
						<Row className='flex-column gap-3 gap-md-0 flex-md-row ms-1'>
							{data.owner.name && (
								<Col className='d-flex flex-column gap-1 text-box'>
									<Col className='fw-bold'>{t('adminPage.results.owner')}</Col>
									<Col>{data.owner.name}</Col>
								</Col>
							)}
							{data.collection.name && (
								<Col className='d-flex flex-column gap-1 text-box'>
									<Col className='fw-bold'>
										{t('adminPage.results.collection')}
									</Col>
									<Col>{data.collection.name}</Col>
								</Col>
							)}

							{data.category.name && (
								<Col className='d-flex flex-column gap-1 text-box'>
									<Col className='fw-bold'>
										{t('adminPage.results.category')}
									</Col>
									<Col>{data.category.name}</Col>
								</Col>
							)}
						</Row>
						<hr />

						{data.description && (
							<Row className='flex-column gap-2 p-0'>
								<Col className='p-0'>
									<Heading
										variant='h3'
										className='fs-5 font-medium'
										id='adm-results-description-heading'
									>
										{t('adminPage.results.description')}
									</Heading>
								</Col>

								<Col className='p-0'>{parse(data.description)}</Col>
							</Row>
						)}
						{hasInactiveCodeValues?.includes(true) ||
						hasInactiveCodeValues?.length === 0 ? null : (
							<span>
								<Button
									size='sm'
									onClick={() => setshowInactiveCodes(!showInactiveCodes)}
								>
									{showInactiveCodes
										? t('adminPage.results.hideInactiveCodes')
										: t('adminPage.results.showInactiveCodes')}
								</Button>
							</span>
						)}
						{hasInactiveCodeValues?.length !== 0 && (
							<>
								{hasInactiveCodeValues?.includes(true) || showInactiveCodes ? (
									<>
										<Row className='flex-column gap-3 flex-xl-row'>
											<Col className='p-0'>
												<Heading
													variant='h3'
													className='fs-5 font-medium'
													id='adm-codevalues-heading'
												>
													{t('adminPage.results.codeValues')}
												</Heading>
											</Col>

											<Col
												xs='auto'
												className='p-0 d-flex flex-wrap gap-3 flex-sm-row justify-content-end align-content-end'
											>
												{hasInactiveCodeValues?.includes(false) &&
													hasInactiveCodeValues?.includes(true) && (
														<span className='align-self-end'>
															<Button
																size='sm'
																onClick={() =>
																	setshowInactiveCodes(!showInactiveCodes)
																}
															>
																{showInactiveCodes
																	? t('adminPage.results.hideInactiveCodes')
																	: t('adminPage.results.showInactiveCodes')}
															</Button>
														</span>
													)}
												{hasInactiveCodeValues?.includes(true) && (
													<>
														<span className='align-self-end'>
															<Button
																onClick={() =>
																	window.open(
																		`${endpoints.apiAdmEndpointDev}/${data.id}/download/EXCEL`
																	)
																}
																icon={faFileExcel}
																size='sm'
															>
																{t(
																	'adminPage.downloadCodeWorks.downloadCodeWorks'
																)}{' '}
																({data.id})
															</Button>
														</span>
														<span className='align-self-end'>
															<Button
																onClick={
																	codeValues.includes(data.id)
																		? () => handleRemoveCodeValue(data.id)
																		: () => handleAddCodeValue(data.id)
																}
																icon={
																	codeValues.includes(data.id)
																		? faClose
																		: faDownload
																}
																size='sm'
															>
																{codeValues.includes(data.id)
																	? t(
																			'adminPage.downloadCodeWorks.removeFromList'
																	  )
																	: t(
																			'adminPage.downloadCodeWorks.addToDownload'
																	  )}
															</Button>
														</span>
													</>
												)}
											</Col>
										</Row>

										<Row className='d-flex flex-column gap-3 p-0 m-0'>
											<Table responsive striped hover className='p-0'>
												<thead>
													<tr>
														<th>{t('adminPage.results.value')}</th>
														<th>{t('adminPage.results.name')}</th>
														<th>{t('adminPage.results.explaination')}</th>
														<th style={{ textAlign: 'right' }}>
															{t('adminPage.results.status')}
														</th>
													</tr>
												</thead>
												<tbody>
													{data.codeValues
														.sort(sortCodeValues)
														.map((item, i) => {
															if (item.active === false && !showInactiveCodes) {
																return null
															}
															return (
																<tr key={`${item.sortKey}-${i}`}>
																	<td>{item.value}</td>
																	<td>{item.name}</td>
																	<td>{item.description}</td>
																	<td style={{ textAlign: 'right' }}>
																		<Tag
																			color={item.active ? 'green' : 'red'}
																			text={
																				item.active
																					? t('general.active')
																					: t('general.inactive')
																			}
																		/>
																	</td>
																</tr>
															)
														})}
												</tbody>
											</Table>
										</Row>
									</>
								) : null}
							</>
						)}
					</article>
					<DownloadContainer>
						<StyledToast
							onClose={() => setShowToast(false)}
							show={showToast}
							delay={toastStatus === 'sucessDownloadAll' ? 8000 : 3000}
							autohide
							role='alert'
							$isGreen={getToastColour(toastStatus) === 'green'}
						>
							<FontAwesomeIcon
								icon={
									getToastColour(toastStatus) === 'green' ? faCheck : faClose
								}
							/>
							<span className='ms-2'>{getToastText(toastStatus)}</span>
						</StyledToast>
						{codeValues.length > 0 && (
							<BSToast>
								<Accordion defaultActiveKey='0' flush>
									<Accordion.Item eventKey='0'>
										<Accordion.Header>
											<strong className='me-auto'>
												{t('adminPage.downloadCodeWorks.filesToDownload')}{' '}
												<small>({codeValues.length})</small>
											</strong>
										</Accordion.Header>
										<Accordion.Body
											className='d-flex flex-column overflow-scroll'
											style={{ maxHeight: '300px' }}
										>
											<ListGroup className='pb-5'>
												{codeValues.map((item: string, i: number) => (
													<CodeValuesItem key={i}>
														<span>{item}</span>
														<button
															onClick={() => handleRemoveCodeValue(item)}
															onKeyDown={(event) => {
																if (
																	event.key === 'Enter' ||
																	event.key === ' '
																) {
																	handleRemoveCodeValue(item)
																}
															}}
															tabIndex={0}
															className='delete-text'
														>
															<span>{t('general.remove')}</span>
															<FontAwesomeIcon icon={faClose} />
														</button>
													</CodeValuesItem>
												))}
											</ListGroup>
											<span
												className='align-self-end mt-2 d-flex position-absolute'
												style={{
													bottom: '9px',
													right: '29px',
													background: 'white',
													padding: '0.5rem',
												}}
												role='group'
											>
												<Button
													icon={faClose}
													onClick={() => handleRemoveAllCodeValues()}
													size='sm'
												>
													{t('adminPage.downloadCodeWorks.removeAll')}
												</Button>
												<Button
													icon={faDownload}
													onClick={() => handleDownloadAllCodeValues()}
													size='sm'
												>
													{t('adminPage.downloadCodeWorks.downloadAll')} (
													{codeValues.length})
												</Button>
											</span>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</BSToast>
						)}
					</DownloadContainer>
				</Wrapper>
			)}
		</ScrollWrapper>
	)
}

export default AdmResults
