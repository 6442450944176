import { Button, Hierarchy, HierarchyAdm } from 'components/common'
import { Col } from 'react-bootstrap'
import { LayoutWrapper, SidebarContent } from './style'
import { MultiColumnLayoutProps } from './interface'
import { useEffect, useLayoutEffect, useState } from 'react'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { useStoreContext } from 'context/StoreProvider'
import { useParams } from 'react-router-dom'
import { isDesktop, isMobile } from 'react-device-detect'
import { Resizable } from 're-resizable'

const SearchResultLayout = ({
	results,
	isClinical,
	isAdm,
}: MultiColumnLayoutProps) => {
	const [showHierarchy, setShowHierarchy] = useState(true)
	const { t } = useTranslation()

	const { state } = useStoreContext()
	const { code, scrollRef, hierarchyScrollRef, codeSystem } = state

	const [sidebarWidth, setSidebarWidth] = useState(
		() => sessionStorage.getItem('sidebarWidth') || '400px'
	)

	const { codeValue } = useParams()

	useEffect(() => {
		if (isDesktop) {
			setShowHierarchy(false)
		}
	}, [isDesktop])

	const isInViewport = () => {
		const offset = 0
		const element = scrollRef.current[code as string]
		if (!element) return false
		const top = element.getBoundingClientRect().top
		return top + offset >= 0 && top - offset <= window.innerHeight
	}

	useLayoutEffect(() => {
		const scrollToElement = async () => {
			const element = scrollRef.current[codeValue as string]
			const hierarchyElement = hierarchyScrollRef.current[codeValue as string]
			if (element && hierarchyElement) {
				// Scroll to the second element
				hierarchyElement.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				})

				// Wait for a small delay
				await new Promise((resolve) => setTimeout(resolve, 400))

				if (!isInViewport()) {
					element.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					})
				}
			}
		}

		const delay = 800

		const timeoutId = setTimeout(scrollToElement, delay)

		return () => clearTimeout(timeoutId)
	}, [code, scrollRef, hierarchyScrollRef])

	useEffect(() => {
		// Update the sessionStorage whenever the sidebarWidth state changes
		sessionStorage.setItem('sidebarWidth', sidebarWidth)
	}, [sidebarWidth])

	const headerHeight = document.getElementById('site-header')?.clientHeight || 0
	const tabNavHeight =
		document.getElementById('codesystem-tab-nav')?.clientHeight || 0
	const searchWrapperHeight =
		document.getElementById('search-wrapper')?.clientHeight || 0
	const tabViewHeight =
		document.getElementById(`tab-view-${codeSystem}`)?.clientHeight || 0

	const calculateHeight =
		headerHeight + tabNavHeight + searchWrapperHeight + tabViewHeight

	const isMobileOrSmallScreen = isMobile || window.innerWidth < 992

	return (
		<LayoutWrapper fluid>
			{isMobileOrSmallScreen && (isClinical || isAdm) ? (
				<Button
					variant='tertiary'
					icon={!showHierarchy ? faChevronDown : faChevronUp}
					className='justify-content-center d-flex w-100 mb-4 mt-2'
					size='sm'
					iconright
					color='grey'
					onClick={() => setShowHierarchy(!showHierarchy)}
					textColor='black'
				>
					{!isAdm
						? !showHierarchy
							? t('general.showHierarchy')
							: t('general.hideHierarchy')
						: !showHierarchy
						? t('general.showCollections')
						: t('general.hideCollections')}
				</Button>
			) : null}

			<div className='w-100 d-flex flex-column flex-lg-row'>
				{isMobileOrSmallScreen && showHierarchy ? (
					<>
						{isClinical && (
							<SidebarContent className={`d-flex `} xs='auto' $isAdm={isAdm}>
								<Hierarchy />
							</SidebarContent>
						)}
						{isAdm && (
							<Col xs='auto'>
								<SidebarContent className={`d-flex `} $isAdm={isAdm}>
									<HierarchyAdm />
								</SidebarContent>
							</Col>
						)}
					</>
				) : null}
				{isMobileOrSmallScreen && showHierarchy && (isClinical || isAdm) && (
					<Button
						variant='tertiary'
						icon={!showHierarchy ? faChevronDown : faChevronUp}
						className='justify-content-center d-flex w-100 mb-2'
						size='sm'
						iconright
						color='grey'
						onClick={() => setShowHierarchy(!showHierarchy)}
						textColor='black'
					>
						{!isAdm
							? !showHierarchy
								? t('general.showHierarchy')
								: t('general.hideHierarchy')
							: !showHierarchy
							? t('general.showCollections')
							: t('general.hideCollections')}
					</Button>
				)}

				{isDesktop && window.innerWidth > 992 && (
					<Resizable
						size={{
							width: sidebarWidth,
							height: '100%',
						}}
						onResizeStop={(e, direction, ref) => {
							setSidebarWidth(`${ref.offsetWidth}px`)
						}}
						maxWidth='60%'
						minWidth='10%'
						enable={{ right: true }}
						handleStyles={{
							right: {
								width: '2px',
								height: `100%`,
								cursor: 'col-resize',
								background: '#6C757D',
							},
						}}
					>
						{isClinical && (
							<SidebarContent
								xs='auto'
								$isAdm={isAdm}
								$topValue={calculateHeight + 10}
							>
								<Hierarchy />
							</SidebarContent>
						)}

						{isAdm && (
							<SidebarContent
								xs='auto'
								$isAdm={isAdm}
								$topValue={calculateHeight}
							>
								<HierarchyAdm />
							</SidebarContent>
						)}
					</Resizable>
				)}

				{results}
			</div>
		</LayoutWrapper>
	)
}

export default SearchResultLayout
