import { Heading, Wrapper } from 'components/common'
import ElasticSearch from 'components/search/buckets/ElasticSearch'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const SearchSection = () => {
	const { t } = useTranslation()

	return (
		<Wrapper className={`d-flex flex-column gap-2 py-5 `}>
			<Row
				as={'section'}
				className={`p-0 m-0 w-100 position-relative align-items-center justify-content-center m-auto`}
			>
				<label id='downshift-1-label'>
					<Heading
						variant='h1'
						className={`text-center pb-1 fw-bold`}
						id='search-section-heading'
					>
						{t('general.searchTitle', {
							term: t('frontpage.title'),
						})}
					</Heading>
				</label>
			</Row>

			<ElasticSearch />
		</Wrapper>
	)
}

export default SearchSection
