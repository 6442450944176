const ehelseUrl = 'https://www.ehelse.no'
const ehelseKodeverk = ehelseUrl + '/kodeverk-og-terminologi'
const helsedirUrl = 'https://www.helsedirektoratet.no'

const aboutLab = '/Laboratoriekodeverk'
const aboutNorpat = '/Norsk-patologikodeverk'
const aboutSnomedct = '/SNOMED-CT'
const aboutIcd = '/ICD-10-og-ICD-11'
const aboutIcpc = '/ICPC-2'
const aboutPhbu =
	'/Multiaksial-klassifikasjon-i-psykisk-helsevern-for-barn-og-unge-(PHBU)'
const aboutNkpk = '/Norsk-klinisk-prosedyrekodeverk-(NKPK)'
const aboutKis = '/kodeverk-i-e-helsestandarder'

const reportErrorLab = 'lab@helsedir.no'
const reportErrorEmail = 'kodehjelp@helsedir.no'
const reportErrorAdm = 'kodehjelp@helsedir.no'
const koteUrl = 'https://kote.helsedirektoratet.no'
const terminologiString = '/terminologi'
const kliniskeString = '/kliniske'

const snomedctBrowser = 'https://snowstorm.terminologi.helsedirektoratet.no'

/* Temporary download files until the api works well with clinical codesystems */
const downloadExcelIcdTemp =
	'/ICD-10-og-ICD-11/_/attachment/inline/4d2b7160-407d-417a-b848-112002cc025c:4246e4ef5745de04307a4d5ae3a2a23dd23dc47f/Kodeliste%20ICD-10%202023%20oppdatert%2013.12.22.xlsx'
const downloadExcelIcpcTemp =
	'/ICPC-2/_/attachment/inline/398f39f0-8906-48dc-b029-397ac80923b1:c289017d287eb2ffa5b135e8c7a5f4f6e7e6e702/Fil%201%202023%20-%20ICPC-2%20koderegister%20med%20utvidet%20termsett%20(flere%20linjer%20per%20kode)%20(Excel).xlsx'
const downloadExcelNkpkTemp =
	'/Norsk-klinisk-prosedyrekodeverk-(NKPK)/_/attachment/inline/f6416880-863b-443f-b6da-f7a6d1abc8f5:a7eeea08041c528d4e8cf73ac5039d4e87ba42a5/Alle%20prosedyrekoder%202023%20oppdatert%2021.11.2022%20(1).xlsx'
const downloadExcelPhbuTemp =
	'/Multiaksial-klassifikasjon-i-psykisk-helsevern-for-barn-og-unge-(PHBU)/_/attachment/inline/f270bc17-61cd-4eb2-9bb1-51493ebffcb0:31600062b59fff229363e28277d2e89af461e67b/Kodeliste%20PHBU%202023.xlsx'
const downloadCsvNlkTemp =
	'/Laboratoriekodeverk/_/attachment/inline/18b2c07f-2662-4762-9561-0465530cba19:c3ff952e1c91917e25235aff9448cdbfdd31b82c/Norsk%20laboratoriekodeverk%207280.69.csv'

const ehelseFeedbackForm =
	'https://forms.office.com/Pages/ResponsePage.aspx?id=XL2haw911kqrow-VWFvCH_vvJjJGPaVFqLMA_mOzyM5URE81V1oyM1VMQkNDOUxXUUlKUFZVUFIxVC4u'

const externalUrls = {
	ehelseUrl,
	helsedirUrl,
	ehelseKodeverk,

	/* about */
	aboutLab: ehelseKodeverk + aboutLab,
	aboutNorpat: ehelseKodeverk + aboutNorpat,
	aboutSnomedct: ehelseKodeverk + aboutSnomedct,
	aboutIcd: ehelseKodeverk + aboutIcd,
	aboutIcpc: ehelseKodeverk + aboutIcpc,
	aboutPhbu: ehelseKodeverk + aboutPhbu,
	aboutNkpk: ehelseKodeverk + aboutNkpk,
	aboutKis: ehelseKodeverk + aboutKis,

	/* report error */
	reportErrorClinical: koteUrl + kliniskeString,
	reportErrorTerminology: koteUrl + terminologiString,
	reportErrorLab,
	reportErrorEmail,
	reportErrorAdm,
	ehelseFeedbackForm,

	/* temp download file urls */
	downloadExcelIcdTemp: ehelseKodeverk + downloadExcelIcdTemp,
	downloadExcelIcpcTemp: ehelseKodeverk + downloadExcelIcpcTemp,
	downloadExcelNkpkTemp: ehelseKodeverk + downloadExcelNkpkTemp,
	downloadExcelPhbuTemp: ehelseKodeverk + downloadExcelPhbuTemp,
	downloadCsvNlkTemp: ehelseKodeverk + downloadCsvNlkTemp,

	snomedctBrowser,
}

export default externalUrls
