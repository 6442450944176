import type { FilterType, FilterValue } from '@elastic/search-ui'
import resultFields from './resultFields'
import searchFields from 'components/search/buckets/SearchWithFilter/config/settings/searchFields'
import { codesystems } from 'utils'

const searchQuery = {
	disjunctiveFacets: ['code_system', 'active', 'status_text'],
	facets: {
		code_system: {
			type: 'value',
		},
		active: {
			type: 'value',
		},
		status_text: {
			type: 'value',
		},
	},
	search_fields: searchFields,
	filters: [
		{
			field: 'code_system',
			values: [
				codesystems.icd10,
				codesystems.phbu,
				codesystems.icpc2,
				codesystems.icpc2b,
				codesystems.nkpk,
				codesystems.nlk,
				codesystems.anatomisk,
				codesystems.tekstligeresultatverdier,
				codesystems.undersokelsesmetode,
				codesystems.norpat,
				codesystems.provemateriale,
			],
			type: 'any' as FilterType,
		},
		{
			field: 'active',
			values: ['true' as FilterValue],
			type: 'any' as FilterType,
		},
	],

	result_fields: resultFields,
}

export default searchQuery
