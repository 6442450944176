import { Sorting } from '@elastic/react-search-ui'
import type { SortingContainerProps } from '@elastic/react-search-ui-views'
import { styled } from 'styled-components'
import { FilterTitle } from '../base'
import { over } from 'utils'

const Wrapper = styled.span`
	.sui-select__control {
		border: 1px solid ${(props) => props.theme.colours.primary.greyShade};
		border-radius: 3px;
		box-shadow: none;
		background-color: ${(props) => props.theme.colours.primary.white};
		font-size: 16px;
	}
	.sui-select__menu {
		position: relative;

		${over('xl')} {
			position: absolute;
		}
	}
`

interface Props extends Pick<SortingContainerProps, 'sortOptions'> {
	label: string
}

const SortingView = ({ sortOptions, label }: Props) => {
	return (
		<Wrapper>
			<FilterTitle htmlFor='react-select-3-input' hasBorderBottom>
				<label id='sorting-label' htmlFor='react-select-3-input'>
					{label}
				</label>
			</FilterTitle>
			<Sorting className='mt-1' sortOptions={sortOptions} />
		</Wrapper>
	)
}

export default SortingView
