import { withSearch } from '@elastic/react-search-ui'
import { useStoreContext } from 'context/StoreProvider'
import { Col, Nav, Tab } from 'react-bootstrap'
import BodyResults from '../BodyResults'
import { useEffect } from 'react'
import SnomedResults from 'components/search/results/SnomedResults'
import { TabWrapper } from 'components/search/buckets/SearchWithFilter/common/TabView/style'
import { useNavigate, useParams } from 'react-router-dom'

interface TabViewProps {
	totalResults: number
	resultSearchTerm: string
	searchTerm: string
}

const TabView = ({
	totalResults,
	resultSearchTerm,
	searchTerm,
}: TabViewProps) => {
	const { state } = useStoreContext()
	const { code } = state
	const { view } = useParams()
	const navigate = useNavigate()

	const searchString = 'search'
	const resultString = 'result'

	const handleSetActiveTab = (newActiveKey: string) => {
		if (view === newActiveKey) {
			return null
		} else {
			navigate(newActiveKey)
		}
	}

	useEffect(() => {
		if (view === undefined) {
			navigate(searchString)
		}
		if (searchTerm !== resultSearchTerm && view === resultString) {
			navigate(searchString)
		}
	}, [view, searchTerm])

	const headerHeight = document.getElementById('site-header')?.clientHeight || 0
	const searchWrapperHeight =
		document.getElementById('snomed-search-wrapper')?.clientHeight || 0

	const calculateHeight = headerHeight + searchWrapperHeight

	return (
		<>
			<Tab.Container
				id='tab-search'
				activeKey={view}
				onSelect={(k) => navigate(k as string)}
			>
				<TabWrapper $topValue={calculateHeight} className='flex-column'>
					<Col
						id={`tab-view-snomed`}
						className='tab-bar d-flex flex-column-reverse flex-lg-row justify-content-between p-0'
					>
						<Nav className='flex-row'>
							<Nav.Item tabIndex={0}>
								<Nav.Link
									onClick={() => handleSetActiveTab(searchString)}
									eventKey={searchString}
								>{`Søkeresultat${
									totalResults > 0 && resultSearchTerm
										? ` (${totalResults} treff)`
										: ''
								}`}</Nav.Link>
							</Nav.Item>
							<Nav.Item tabIndex={0} aria-disabled={!code}>
								<Nav.Link
									eventKey={resultString}
									onClick={() => handleSetActiveTab(resultString)}
									disabled={!code}
								>{`Resultat ${code ? `(${code})` : ''}`}</Nav.Link>
							</Nav.Item>
						</Nav>
					</Col>
					<Col>
						<Tab.Content>
							<Tab.Pane eventKey={searchString}>
								<BodyResults />
							</Tab.Pane>
							<Tab.Pane eventKey={resultString}>
								<SnomedResults />
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</TabWrapper>
			</Tab.Container>
		</>
	)
}

export default withSearch(({ totalResults, resultSearchTerm, searchTerm }) => ({
	totalResults,
	resultSearchTerm,
	searchTerm,
}))(TabView)
