import { createGlobalStyle } from 'styled-components'
import './bootstrap-overrides.css'
import './elastic-overrides.css'

export const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    position: relative;
    font-family: 'Inter', Helvetica, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    min-height: 100%;
    background-color: ${(props) => props.theme.colours.primary.white};
  }

a.text-decoration-none {
     color:${(props) => props.theme.colours.primary.hardBlue} !important;
}


  .AppContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 1 0 auto;
  }
  footer {
    flex-shrink: 0;
  }
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }
  p {
    padding: 0 !important;
  }

  .skip-link {
    position: absolute;
    top: -100px;
    left: 0;
    background: ${(props) => props.theme.colours.primary.hardBlue};
    color: ${(props) => props.theme.colours.primary.white};
    padding: 0.8rem 1.4rem;
    border-radius: 3px;
    z-index: 100;
    transition: top 0.3s;
  }

  .skip-link:focus {
    top: 0;
  }
`
