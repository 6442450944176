import { Navbar } from 'react-bootstrap'
import { styled } from 'styled-components'
import { over } from 'utils'

export const HeaderWrapper = styled(Navbar)`
	background-color: ${(props) => props.theme.colours.primary.white};
	color: ${(props) => props.theme.colours.primary.blue};
	top: 0;
	flex: 60px 0 0;
	padding: 0;
	border-bottom: 1px solid ${(props) => props.theme.colours.primary.blue};
	z-index: 9;

	.nav-link {
		color: ${(props) => props.theme.colours.primary.black};
		font-size: 16px;
		padding-bottom: 0;
		&:hover {
			color: ${(props) => props.theme.colours.primary.blue};
			text-decoration: underline;
		}
	}

	.dropdown-menu {
		border: 1px solid ${(props) => props.theme.colours.primary.greyShade};
		background-color: ${(props) => props.theme.colours.primary.white};
	}

	.dropdown-menu.show {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}

	.navbar-toggler {
		display: flex;
		gap: 0.4rem;
		align-items: center;
		border-radius: 500px;
		padding: 0.6rem 1rem;
		font-size: 16px;
		font-weight: 600;
		border: 2px solid ${(props) => props.theme.colours.primary.blue};
		${over('lg')} {
			display: none;
		}
		svg {
			color: ${(props) => props.theme.colours.primary.blue};
		}
	}
`

export const LogoStyle = styled(Navbar.Brand)`
	position: relative;
	color: ${(props) => props.theme.colours.primary.blue};
	cursor: pointer;
	z-index: 99;
`
