import { Row } from 'react-bootstrap'
import { styled } from 'styled-components'

export const TabWrapper = styled(Row)<{ $topValue: string }>`
	border-bottom: 1px solid ${(props) => props.theme.colours.primary.greyShade};
	position: sticky;
	top: ${({ $topValue }) => `${$topValue}px`};
	background-color: ${(props) => props.theme.colours.primary.white};
	z-index: 1;

	.nav {
		border-color: ${(props) => props.theme.colours.primary.greyShade};
		padding-top: 0.6rem;

		.nav-link {
			color: ${(props) => props.theme.colours.primary.black};
			padding: 0.2rem 0.8rem;
			&:hover {
				color: ${(props) => props.theme.colours.primary.blue};
				border: unset;
			}
		}

		.active {
			color: ${(props) => props.theme.colours.primary.blue};
			border: unset;
			border-bottom: 2px solid ${(props) => props.theme.colours.primary.blue};
			&:hover {
				border-bottom: 2px solid ${(props) => props.theme.colours.primary.blue};
			}
		}
	}
`
