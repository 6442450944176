import { ErrorBoundary, Paging, SearchBox } from '@elastic/react-search-ui'
import {
	Button,
	ErrorMessage,
	Heading,
	InputView,
	Loading,
	Options,
} from 'components/common'
import { Col, Row } from 'react-bootstrap'
import { useState } from 'react'
import { paths, scrollToTop } from 'utils'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { SearchPageLayoutProps } from './interface'
import { ScrollWrapper, SidebarWrapper, Wrapper } from './style'
import { useLocation } from 'react-router-dom'
import { useStoreContext } from 'context/StoreProvider'
import { SearchSectionWrapper } from '../../SearchWithFilter/style'
import { isMobile } from 'react-device-detect'
import MetaInfoBar from './MetaInfoBar'

const SearchPageLayout = ({
	headingLabel,
	sidebarContent,
	isLoading,
	results,
	bodyResults,
	searchTerm,
}: SearchPageLayoutProps) => {
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const [showFilter, setShowFilter] = useState<boolean>(
		isMobile || window.innerWidth < 992 ? false : true
	)
	const { state } = useStoreContext()

	const { codeSystem } = state

	const isResult = pathname.startsWith(paths.result)

	const noResultsAfterSearch = (results as string[]).length > 0

	const headerHeight = document.getElementById('site-header')?.clientHeight || 0

	const isMobileOrSmallScreen = isMobile || window.innerWidth < 992
	return (
		<Wrapper>
			<SearchSectionWrapper
				$topValue={headerHeight + 1}
				id='search-wrapper'
				className='pb-2'
			>
				<Row className='p-0 align-content-center align-items-end justify-content-between flex-column-reverse flex-sm-row gap-2 pt-2'>
					<Col
						as={'label'}
						className='p-0'
						id={`${codeSystem}-search-label`}
						htmlFor={`${codeSystem}-search-input`}
					>
						<Heading
							variant='h1'
							className='fs-4 fw-bold fs-md-3 mb-2'
							id={`${codeSystem}-page-title`}
						>
							{headingLabel}
						</Heading>
					</Col>
					{!isResult && (
						<Col xs='auto' className='p-0'>
							<Options />
						</Col>
					)}
				</Row>

				<ErrorBoundary>
					<SearchBox
						searchAsYouType
						autocompleteResults={false}
						autocompleteSuggestions={false}
						shouldClearFilters={false}
						debounceLength={500}
						inputView={InputView}
					/>
				</ErrorBoundary>
			</SearchSectionWrapper>
			<Row className='row-lg-cols-2 gap-3 gap-lg-0 pt-2 pt-lg-0'>
				{isMobileOrSmallScreen && (
					<Button
						variant='tertiary'
						icon={!showFilter ? faArrowDown : faArrowUp}
						className='justify-content-between d-flex d-lg-none'
						size='md'
						iconright
						color='grey'
						onClick={() => setShowFilter(!showFilter)}
						textColor='black'
					>
						{!showFilter
							? t('nlkPage.filter.showFilter')
							: t('nlkPage.filter.hideFilter')}
					</Button>
				)}

				{showFilter && (
					<SidebarWrapper
						className={`d-flex flex-column gap-4 d-lg-flex ps-0`}
						xs={12}
						lg={3}
						xl={3}
						xxl={3}
						$topValue={headerHeight + 105}
					>
						{sidebarContent}
					</SidebarWrapper>
				)}

				<Col className='p-0' lg={9} id='search-results'>
					{!isLoading && <MetaInfoBar />}
					{isLoading ? (
						<Loading />
					) : noResultsAfterSearch ? (
						<>
							<ScrollWrapper>{bodyResults}</ScrollWrapper>

							{searchTerm && !isLoading && (
								<Row onClick={scrollToTop} className='py-3'>
									<Paging />
								</Row>
							)}
						</>
					) : (
						<ErrorMessage noResults searchTerm={searchTerm} className='mt-5' />
					)}
				</Col>
			</Row>
		</Wrapper>
	)
}

export default SearchPageLayout
