import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
	useInfiniteQuery,
	QueryErrorResetBoundary,
} from '@tanstack/react-query'
import { Chapter, ClinicalResponse } from './interface'
import { useInView } from 'react-intersection-observer'
import Content from './Content'
import { Button, ErrorMessage, Loading, ScrollWrapper } from 'components/common'
import { useStoreContext } from 'context/StoreProvider'
import { codesystemsEndpointName, fetchApi, getApiEndpoint } from 'utils'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

const ChapterResults = () => {
	const [refNext, inViewNext] = useInView({
		triggerOnce: false,
	})
	const [refPrev, inViewPrev] = useInView({
		triggerOnce: false,
	})

	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const [scrollPositionFromBottom, setScrollPositionFromBottom] = useState(0)

	const { t } = useTranslation()

	const [content, setContent] = useState<Chapter[]>([])
	const [fetchError, setFetchError] = useState<string>('')

	const { state } = useStoreContext()

	const { scrollRef, codeSystem, code } = state

	const {
		data: chapterData,
		isFetching,
		isError,
		isFetchingNextPage,
		isFetchingPreviousPage,
		fetchNextPage,
		fetchPreviousPage,
		hasNextPage,
		hasPreviousPage,
	} = useInfiniteQuery<Chapter, Error>({
		queryKey: [codeSystem, code],
		initialPageParam: 0,
		queryFn: ({ pageParam = 1 }) =>
			fetchApi(
				getApiEndpoint(codeSystem as string, code as string, {
					pageParam,
					isChapterView: true,
					includeInactive: codeSystem === codesystemsEndpointName.norpat,
				}),
				setFetchError('404')
			),
		getNextPageParam: (lastPage) =>
			lastPage.totalPages !== lastPage.pageNumber
				? lastPage.pageNumber + 1
				: undefined,
		getPreviousPageParam: (firstPage) =>
			firstPage.pageNumber !== 1 ? firstPage.pageNumber - 1 : undefined,
		enabled: !!code,
		retry: false,
	})

	useEffect(() => {
		if (inViewNext) {
			const timeoutId = setTimeout(() => {
				void fetchNextPage()
			}, 300) // Delay of 2 seconds

			// Clear the timeout when the component is unmounted
			return () => clearTimeout(timeoutId)
		}
	}, [fetchNextPage, inViewNext])

	// Kommenterer denne ut for nå siden automatisk lastin av innhold over gjør automatisk scroll ubrukelig, må se nærmere på hvordan dette kan fikses

	// useLayoutEffect(() => {
	// 	const timeoutId = setTimeout(() => {
	// 		if (inViewPrev) {
	// 			// Save the current scroll position relative to the bottom before fetching the previous page
	// 			if (scrollContainerRef.current) {
	// 				setScrollPositionFromBottom(
	// 					scrollContainerRef.current.scrollHeight -
	// 						scrollContainerRef.current.scrollTop
	// 				)
	// 			}

	// 			// Add a delay before fetching the previous page
	// 			const timeoutId2 = setTimeout(() => {
	// 				void fetchPreviousPage()
	// 			}, 300) // Delay of 2 seconds

	// 			// Clear the timeout when the component is unmounted
	// 			return () => clearTimeout(timeoutId2)
	// 		}
	// 	}, 800) // Delay of 2 seconds

	// 	// Clear the timeout when the component is unmounted
	// 	return () => clearTimeout(timeoutId)
	// }, [fetchPreviousPage, inViewPrev])

	useEffect(() => {
		if (!isFetchingPreviousPage && scrollContainerRef.current) {
			// Create a MutationObserver to observe changes in the scrollable container
			const observer = new MutationObserver(() => {
				// Restore the scroll position relative to the bottom when new elements are added
				if (scrollContainerRef.current) {
					scrollContainerRef.current.scrollTop =
						scrollContainerRef.current.scrollHeight - scrollPositionFromBottom
				}
			})

			// Start observing the scrollable container
			if (scrollContainerRef.current) {
				observer.observe(scrollContainerRef.current, { childList: true })
			}

			// Stop observing the scrollable container when the component is unmounted
			return () => observer.disconnect()
		}
	}, [isFetchingPreviousPage, scrollPositionFromBottom])

	useEffect(() => {
		if (chapterData?.pages !== undefined) {
			setContent(chapterData.pages.flat())
		}
	}, [chapterData?.pages])

	return (
		<ScrollWrapper
			id='chapter-content'
			className='d-flex flex-column gap-4 p-0 pt-lg-4 px-lg-4'
			tabIndex={0}
			ref={scrollContainerRef}
		>
			{isFetching && <Loading />}
			{isError && <ErrorMessage errorResponse={fetchError} />}
			{hasPreviousPage && (
				<span className='d-flex justify-content-center'>
					<Button
						innerRef={refPrev}
						variant='tertiary'
						onClick={() => fetchPreviousPage()}
						disabled={!hasPreviousPage || isFetchingPreviousPage}
						size='sm'
					>
						{isFetchingPreviousPage
							? t('resultPage.loadingMore')
							: hasPreviousPage && t('resultPage.loadMore')}
					</Button>
				</span>
			)}

			<QueryErrorResetBoundary>
				{({ reset }) => (
					<ErrorBoundary
						fallbackRender={({ error, resetErrorBoundary }) => (
							<div>
								{t('general.error')}
								<Button onClick={() => resetErrorBoundary()}>
									{t('general.tryAgain')}
								</Button>
								<pre style={{ whiteSpace: 'normal' }}>{error.message}</pre>
							</div>
						)}
						onReset={reset}
					>
						{content &&
							!isError &&
							content.map(
								(page) =>
									Array.isArray(page.data) &&
									page.data.map((chapter: ClinicalResponse) => (
										<Content
											key={chapter.codeValue}
											chapter={chapter}
											codeSystem={codeSystem as string}
											activeCode={code === chapter.codeValue}
											innerRef={(contentRef: HTMLDivElement | null) =>
												(scrollRef.current[chapter.codeValue] = contentRef)
											}
										/>
									))
							)}
					</ErrorBoundary>
				)}
			</QueryErrorResetBoundary>

			{hasNextPage && (
				<span className='d-flex justify-content-center'>
					<Button
						innerRef={refNext}
						onClick={() => fetchNextPage()}
						disabled={!hasNextPage || isFetchingNextPage}
						variant='tertiary'
						size='sm'
					>
						{isFetchingNextPage
							? t('resultPage.loadingMore')
							: hasNextPage && t('resultPage.loadMore')}
					</Button>
				</span>
			)}
		</ScrollWrapper>
	)
}

export default ChapterResults
