import type {
	FilterType,
	FilterValue,
	SearchResult,
	SortOption,
} from '@elastic/search-ui/lib/esm/types'
import { Table } from 'react-bootstrap'
import { getRaw } from 'utils'
import { useStoreContext } from 'context/StoreProvider'
import { Tag } from 'components/common'
import { withSearch } from '@elastic/react-search-ui'
import { useTranslation } from 'react-i18next'
import { TableWrapper } from './style'
import { Link } from 'react-router-dom'
import { processText } from 'components/search/buckets/utils'
import { useLayoutEffect } from 'react'
import { isMobile } from 'react-device-detect'

interface SmallLabResultViewProps {
	results: SearchResult
	setSearchTerm: (value: string) => void
	searchTerm: string
	setSort: (sort: SortOption[] | string, sortDirection?: string) => void
	sortField: string
	addFilter: (field: string, value: FilterValue[], type: FilterType) => void
}

const SmallLabResultView = ({
	results,
	setSearchTerm,
	searchTerm,
	setSort,
	sortField,
	addFilter,
}: SmallLabResultViewProps) => {
	const { state, actions } = useStoreContext()
	const { t } = useTranslation()

	const { codeSystem, showInactiveOnLoad } = state
	const { updateShowInactiveOnLoad } = actions

	const handleClickReplacedByCode = (replacedByCode: string) => {
		setSearchTerm(replacedByCode)
		addFilter('active', ['true', 'false'], 'any')
	}
	useLayoutEffect(() => {
		if (!sortField) {
			setSort([{ field: 'code', direction: 'asc' }])
		}
	}, [])

	useLayoutEffect(() => {
		if (showInactiveOnLoad) {
			addFilter('active', ['false', 'true'], 'any')
			updateShowInactiveOnLoad(false)
		}
	}, [])

	const headerHeight = document.getElementById('site-header')?.clientHeight || 0
	const tabNavHeight =
		document.getElementById('codesystem-tab-nav')?.clientHeight || 0
	const searchWrapperHeight =
		document.getElementById('search-wrapper')?.clientHeight || 0
	const resultMetaBarHeight =
		document.getElementById('result-meta')?.clientHeight || 0

	const calculateHeight =
		headerHeight + tabNavHeight + searchWrapperHeight + resultMetaBarHeight + 3

	const tableHeaderHeightCondition =
		isMobile || window.innerWidth < 992 ? 0 : calculateHeight

	return (
		<TableWrapper>
			<Table responsive='md' id={`${codeSystem}-table`}>
				<thead>
					<tr>
						<th
							style={{
								top: tableHeaderHeightCondition,
							}}
						>
							{t('general.codeValue')}
						</th>
						<th
							style={{
								top: tableHeaderHeightCondition,
							}}
						>
							{t('general.codeText')}
						</th>
						<th
							style={{
								top: tableHeaderHeightCondition,
							}}
							align='right'
						>
							{t('general.status')}
						</th>
						<th
							style={{
								top: tableHeaderHeightCondition,
							}}
						>
							{t('general.replacedBy')}
						</th>
						<th
							style={{
								top: tableHeaderHeightCondition,
							}}
						>
							{t('general.validFrom')}
						</th>
						<th
							style={{
								top: tableHeaderHeightCondition,
							}}
						>
							{t('general.validTo')}
						</th>
					</tr>
				</thead>
				<tbody>
					{results.map((result: SearchResult) => {
						if (
							result.code.raw === '8351' ||
							result.code.raw === '8352' ||
							result.code.raw === '8340' ||
							result.code.raw === '8354'
						)
							return null
						return (
							<tr key={getRaw(result, 'code')} id={getRaw(result, 'code')}>
								<td id={`${getRaw(result, 'code')}-col`}>
									{processText('code', searchTerm, false, result)}
								</td>
								<td id={`${getRaw(result, 'name_norwegian')}-col`}>
									{processText('name_norwegian', searchTerm, false, result)}
								</td>
								<td
									id={`${getRaw(result, 'active')}-${getRaw(
										result,
										'code'
									)}-col`}
								>
									<Tag
										color={
											getRaw(result, 'active') === 'true' ? 'green' : 'red'
										}
										text={
											getRaw(result, 'active') === 'true'
												? t('general.active')
												: t('general.inactive')
										}
									/>
								</td>
								<td id={`${getRaw(result, 'replaced_by')}-col`}>
									{getRaw(result, 'replaced_by') && (
										<Link
											onClick={() =>
												handleClickReplacedByCode(
													getRaw(result, 'replaced_by') as string
												)
											}
											to={`#`}
										>
											{processText('replaced_by', searchTerm, false, result)}
										</Link>
									)}
								</td>
								<td id={`${getRaw(result, 'valid_from')}-col`}>
									{processText('valid_from', searchTerm, false, result)}
								</td>
								<td id={`${getRaw(result, 'valid_to')}-col`}>
									{processText('valid_to', searchTerm, false, result)}
								</td>
							</tr>
						)
					})}
				</tbody>
			</Table>
		</TableWrapper>
	)
}

export default withSearch(
	({ results, setSearchTerm, searchTerm, setSort, sortField, addFilter }) => ({
		results,
		setSearchTerm,
		searchTerm,
		setSort,
		sortField,
		addFilter,
	})
)(SmallLabResultView)
