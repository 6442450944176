import type { SearchResult } from '@elastic/search-ui/lib/esm/types'
import { Col } from 'react-bootstrap'
import {
	chapterString,
	codesystems,
	collectionsString,
	getRaw,
	getSnippet,
} from 'utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useStoreContext } from 'context/StoreProvider'
import { useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'
import { ResultViewWrapper } from './style'
import {
	getActiveStatus,
	getStatusText,
	getTagColor,
	handleNavigate,
	highlightSearchTerm,
	processText,
} from 'components/search/buckets/utils'
import { Tag, Link } from 'components/common'
import { t } from 'i18next'
import { SearchResultBar } from 'components/search/buckets/SnomedSearch/common/SnomedResultView/style'
import { withSearch } from '@elastic/react-search-ui'

const ResultView = ({ results, searchTerm }: SearchResult) => {
	const navigate = useNavigate()
	const { actions, state } = useStoreContext()

	const { code, scrollRef } = state

	const { updateCodeValue, updateShowInactiveOnLoad } = actions

	const handleScrollOnClick = () => {
		const scrollToElement = () => {
			const element = scrollRef.current[code as string]
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
		}

		const delay = 500

		const timeoutId = setTimeout(scrollToElement, delay)

		return () => clearTimeout(timeoutId)
	}

	return (
		<SearchResultBar>
			{results.map((result: SearchResult) => {
				const shouldBeDisabled =
					result.active.raw === 'false' &&
					result.code_system.raw !== codesystems.adm

				const isLab =
					result.code_system.raw === codesystems.norpat ||
					result.code_system.raw === codesystems.nlk ||
					result.code_system.raw === codesystems.anatomisk ||
					result.code_system.raw === codesystems.tekstligeresultatverdier ||
					result.code_system.raw === codesystems.undersokelsesmetode ||
					result.code_system.raw === codesystems.provemateriale
				const isLabExceptNorpat =
					result.code_system.raw === codesystems.nlk ||
					result.code_system.raw === codesystems.anatomisk ||
					result.code_system.raw === codesystems.tekstligeresultatverdier ||
					result.code_system.raw === codesystems.undersokelsesmetode ||
					result.code_system.raw === codesystems.provemateriale

				const handleClickReplacedByCode = () => {
					handleScrollOnClick()
					updateCodeValue(getRaw(result, 'replaced_by') as string)
					if (isLabExceptNorpat) {
						navigate(
							`${handleNavigate(getRaw(result, 'code_system') as string)}/?q=${
								getRaw(result, 'replaced_by') as string
							}`
						)
					} else {
						navigate(
							`${handleNavigate(
								getRaw(result, 'code_system') as string
							)}/chapter/${getRaw(result, 'replaced_by') as string}`
						)
					}
				}

				const handleClickedResult = () => {
					if (result.code_system.raw === codesystems.adm) {
						navigate(
							`${handleNavigate(result.code_system.raw)}/${collectionsString}/${
								result.compact_code.raw.split(' ')[0]
							}`
						)
						updateCodeValue(result.compact_code.raw.split(' ')[0])
					} else if (result.code_system.raw === codesystems.icpc2b) {
						navigate(
							`${handleNavigate(result.code_system.raw)}/${chapterString}/${
								result.parent_code.raw
							}`
						)
						updateCodeValue(result.parent_code.raw)
					} else if (isLabExceptNorpat) {
						if (shouldBeDisabled) {
							navigate(
								`${handleNavigate(result.code_system.raw)}/${
									result.code.raw
								}/?q=${result.code.raw}`
							)
							updateShowInactiveOnLoad(true)
							updateCodeValue(result.code.raw)
						}
						navigate(
							`${handleNavigate(result.code_system.raw)}/${
								result.code.raw
							}/?q=${result.code.raw}`
						)
						updateCodeValue(result.code.raw)
					} else {
						if (shouldBeDisabled && !isLab) {
							return null
						} else {
							navigate(
								`${handleNavigate(
									result.code_system.raw
								)}/${chapterString}/${result.code.raw.replace(
									'_',
									''
								)}/?q=${searchTerm}`
							)
							updateCodeValue(result.code.raw.replace('_', ''))
						}
					}
				}
				return (
					<ResultViewWrapper
						action={!shouldBeDisabled || isLab}
						onClick={handleClickedResult}
						$isDisabled={isLab ? false : shouldBeDisabled}
						key={`${getRaw(result, 'code')}  ${getRaw(result, 'code_system')}`}
					>
						{getRaw(result, 'code_system') === codesystems.adm ? (
							<div className='d-flex flex-column gap-2'>
								<Col xs='auto'>
									<span className='fw-bold pb-1 text-wrap'>
										{getRaw(result, 'name_norwegian') && (
											<span className='pb-1 text-break text-md-normal'>
												{processText(
													'name_norwegian',
													searchTerm,
													false,
													result
												)}
											</span>
										)}
									</span>
								</Col>
								<Col
									className='d-flex gap-2 align-items-center flex-wrap'
									xs='auto'
								>
									<span className='fs-6'>
										{getSnippet(result, 'code') &&
											parse(
												highlightSearchTerm(
													result.code.snippet?.indexOf('<em>') === -1
														? result.compact_code.raw.substring(0, 4)
														: result.code.snippet === null
														? getRaw(result, 'compact_code')?.substring(0, 4)
														: getSnippet(result, 'code'),
													searchTerm
												) as string
											)}
									</span>
									{result.status_text.raw && (
										<span className='d-flex gap-2 flex-wrap ms-auto'>
											<Tag
												color='blue'
												text={
													getSnippet(result, 'parent_code')?.slice(4) as string
												}
											/>
											{getRaw(result, 'status_text') &&
												getRaw(result, 'status_text') ===
													'Foreldet og erstattet av annet kodeverk' && (
													<Tag
														color={getTagColor(
															getRaw(result, 'status_text') as string
														)}
														text={getStatusText(
															getRaw(result, 'status_text') as string
														)}
													/>
												)}
										</span>
									)}
									<FontAwesomeIcon
										icon={faChevronRight}
										className='icon-blue'
									/>
								</Col>
							</div>
						) : (
							<div className='d-flex flex-column gap-2'>
								<Col xs='auto' md={5}>
									{getRaw(result, 'name_norwegian') && (
										<span className='fw-bold pb-1 text-break text-md-normal'>
											{processText('name_norwegian', searchTerm, false, result)}
										</span>
									)}
								</Col>
								<Col
									className='d-flex gap-2 align-items-center flex-wrap'
									xs='auto'
								>
									<span className='fs-6'>
										{processText('code', searchTerm, false, result)}
									</span>
									{result.replaced_by &&
										result.replaced_by.raw &&
										result.code_system.raw && (
											<span
												className='d-flex gap-2 align-items-center'
												role='link'
												onClick={(event) => {
													event.stopPropagation()
													handleClickReplacedByCode()
												}}
												onKeyDown={(event) => {
													event.stopPropagation()
													if (event.key === 'Enter' || event.key === ' ') {
														handleClickReplacedByCode()
													}
												}}
												tabIndex={0}
											>
												<span className=''>{t('general.replacedBy')}:</span>{' '}
												<Link
													text={getRaw(result, 'replaced_by') as string}
													href={`#`}
												/>
											</span>
										)}
									<span className='d-flex gap-2 flex-wrap ms-auto'>
										{getRaw(result, 'is_apat') &&
											getRaw(result, 'is_apat') === 'true' && (
												<span className='d-flex flex-column gap-3 flex-md-row'>
													<Tag color={'yellow'} text={codesystems.apat} />
												</span>
											)}
										{result.code_system.raw ? (
											<Tag color='blue' text={result.code_system.snippet} />
										) : null}
										{getRaw(result, 'active') &&
											getRaw(result, 'active') !== 'true' && (
												<Tag
													color={getActiveStatus(result.active.raw)}
													text={
														result.active.raw === 'true' ? 'Aktiv' : 'Inaktiv'
													}
												/>
											)}
									</span>
									<FontAwesomeIcon
										icon={faChevronRight}
										className='icon-blue'
									/>
								</Col>
							</div>
						)}
					</ResultViewWrapper>
				)
			})}
		</SearchResultBar>
	)
}

export default withSearch(({ searchTerm, results }) => ({
	searchTerm,
	results,
}))(ResultView)
