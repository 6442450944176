import { PagingInfo, ResultsPerPage } from '@elastic/react-search-ui'
import { PagingInfoView, ResultsPerPageView } from 'components/common'
import { isSmallLabCodesystem } from 'components/search/buckets/utils'
import { useStoreContext } from 'context/StoreProvider'
import { Col } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { MetaBarWrapper } from './style'
import { paths } from 'utils'

const MetaInfoBar = () => {
	const { pathname } = useLocation()
	const { state } = useStoreContext()
	const { codeSystem } = state

	const isMobileOrSmallScreen = isMobile || window.innerWidth < 992
	const isAdm = pathname.startsWith(paths.adm)
	const isNorpat = pathname.startsWith(paths.norpat)

	const headerHeight = document.getElementById('site-header')?.clientHeight || 0
	const tabNavHeight =
		document.getElementById('codesystem-tab-nav')?.clientHeight || 0
	const searchWrapperHeight =
		document.getElementById('search-wrapper')?.clientHeight || 0
	const tabViewHeight =
		document.getElementById(`tab-view-${codeSystem}`)?.clientHeight || 0

	const calculateHeight = headerHeight + tabNavHeight + searchWrapperHeight

	const calculateHeightClinical = calculateHeight + tabViewHeight

	return isSmallLabCodesystem(pathname) ? (
		<MetaBarWrapper
			className={`align-items-end position-sticky result-meta-bar pt-3 pt-lg-1`}
			id='result-meta'
			$topValue={isMobileOrSmallScreen ? calculateHeight : calculateHeight + 2}
		>
			<PagingInfo view={PagingInfoView} />
		</MetaBarWrapper>
	) : (
		<MetaBarWrapper
			className='align-items-end position-sticky pt-1'
			style={{
				top: isMobileOrSmallScreen
					? calculateHeightClinical + 3
					: isAdm
					? calculateHeightClinical + 1
					: isNorpat
					? calculateHeightClinical + 2
					: calculateHeightClinical + 3,
			}}
			id='result-meta-bar'
		>
			<Col className='p-0'>
				<PagingInfo view={PagingInfoView} />
			</Col>

			<Col xs='auto' className='p-0'>
				<ResultsPerPage
					view={ResultsPerPageView}
					options={[10, 15, 20, 30, 40, 50, 100]}
				/>
			</Col>
		</MetaBarWrapper>
	)
}

export default MetaInfoBar
