import {
	ClearFilters,
	CustomFacet,
	CustomFilter,
	FilterTitle,
} from 'components/common'
import { useTranslation } from 'react-i18next'

const SnomedFilter = () => {
	const { t } = useTranslation()

	return (
		<>
			<span className='d-flex flex-column gap-2 pt-0 pt-lg-2'>
				<FilterTitle
					hasBorderBottom={true}
					className='d-flex justify-content-between'
				>
					{t('nlkPage.filter.title')}
					<ClearFilters />
				</FilterTitle>
			</span>

			<CustomFacet
				field='member_of_refsets'
				label='Referansesett'
				view={CustomFilter}
				filterType={'any'}
				isFilterable
				show={20}
			/>
		</>
	)
}

export default SnomedFilter
