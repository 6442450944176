import { styled } from 'styled-components'
import { over } from 'utils'

export const TableWrapper = styled.div`
	position: relative;
	width: max-content;

	${over('sm')} {
		width: 100%;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
	}

	tr:nth-child(even) {
		background-color: #f2f6fa;
	}

	.active {
		font-weight: bold;
		border: 2px solid ${(props) => props.theme.colours.primary.blue};
		tr {
			background-color: inherit !important;
		}
	}

	td {
		background-color: unset;
		padding: 0.7rem 0.6rem;
	}
	th {
		background-color: white;
		position: sticky !important;
		top: 0;
		border-bottom: 2px solid ${(props) => props.theme.colours.primary.blue};
		padding: 0.7rem 0.6rem;
		${over('lg')} {
			z-index: 1;
		}
	}
`
