import { Routes, Route } from 'react-router-dom'
import { paths } from 'utils'

import { MainPage } from 'pages'
import { ICDPage, ICPCPage, NKPKPage, PHBUPage } from 'pages/Clinical'
import {
	AnatomiskPage,
	NorpatPage,
	ProvematerialePage,
	TekstligeResultatverdierPage,
	NLKPage,
	UndersokelsesmetodePage,
} from 'pages/Lab'
import { AdmPage, SnomedCTPage } from 'pages/Other'
import PageNotFound from 'pages/PageNotFound'
import GeneralSearchResults from 'pages/GeneralSearchResults'

const codeValue = 'codeValue'
const view = 'view'

const Router = () => {
	return (
		<Routes>
			<Route path={paths.main} index element={<MainPage />} />

			<Route path='*' element={<PageNotFound />} />

			{/* Kliniske kodeverk */}
			<Route path={paths.icd10} element={<ICDPage />}>
				<Route path={`:${view}`} element={<ICDPage />} />
				<Route path={`:${view}/:${codeValue}`} element={<ICDPage />} />
			</Route>
			<Route path={paths.icpc2} element={<ICPCPage />}>
				<Route path={`:${view}`} element={<ICPCPage />} />
				<Route path={`:${view}/:${codeValue}`} element={<ICPCPage />} />
			</Route>
			<Route path={paths.nkpk} element={<NKPKPage />}>
				<Route path={`:${view}`} element={<NKPKPage />} />
				<Route path={`:${view}/:${codeValue}`} element={<NKPKPage />} />
			</Route>
			<Route path={paths.phbu} element={<PHBUPage />}>
				<Route path={`:${view}`} element={<PHBUPage />} />
				<Route path={`:${view}/:${codeValue}`} element={<PHBUPage />} />
			</Route>

			{/* Lab kodeverk */}
			<Route path={paths.norpat} element={<NorpatPage />}>
				<Route path={`:${view}`} element={<NorpatPage />} />
				<Route path={`:${view}/:${codeValue}`} element={<NorpatPage />} />
			</Route>
			<Route path={paths.nlk} element={<NLKPage />}>
				<Route path={`:${codeValue}`} element={<NLKPage />} />
			</Route>
			<Route path={paths.anatomisk} element={<AnatomiskPage />}>
				<Route path={`:${codeValue}`} element={<AnatomiskPage />} />
			</Route>
			<Route
				path={paths.tekstligeresultatverdier}
				element={<TekstligeResultatverdierPage />}
			>
				<Route
					path={`:${codeValue}`}
					element={<TekstligeResultatverdierPage />}
				/>
			</Route>
			<Route
				path={paths.undersokelsesmetode}
				element={<UndersokelsesmetodePage />}
			>
				<Route path={`:${codeValue}`} element={<UndersokelsesmetodePage />} />
			</Route>
			<Route path={paths.provemateriale} element={<ProvematerialePage />}>
				<Route path={`:${codeValue}`} element={<ProvematerialePage />} />
			</Route>

			{/* Annet */}
			<Route path={paths.snomed} element={<SnomedCTPage />}>
				<Route path={`:${view}`} element={<SnomedCTPage />} />
				<Route path={`:${view}/:${codeValue}`} element={<SnomedCTPage />} />
			</Route>

			<Route path={`${paths.adm}/`} element={<AdmPage />}>
				<Route path={`:${view}`} element={<AdmPage />} />
				<Route path={`:${view}/:${codeValue}`} element={<AdmPage />} />
			</Route>
			<Route path={`${paths.result}/`} element={<GeneralSearchResults />}>
				<Route path={`:${codeValue}`} element={<GeneralSearchResults />} />
			</Route>
		</Routes>
	)
}

export default Router
