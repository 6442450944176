import { Col, Container } from 'react-bootstrap'
import { styled } from 'styled-components'
import { over } from 'utils'

export const LayoutWrapper = styled(Container)`
	padding: 0;
	display: flex;
	flex-direction: column;

	${over('lg')} {
		flex-direction: row;
		column-gap: 1rem;
	}
	.search-bar {
		padding-bottom: 3rem;
		padding-top: 1rem;
		width: 100%;
		background-color: white;
		position: sticky;
		z-index: 1;
	}
`

export const SidebarContent = styled(Col)<{
	$isAdm: boolean
	$topValue: string
}>`
	position: sticky;
	top: ${({ $topValue }) => `${$topValue}px`};
	margin-bottom: 10px;
	overflow: auto;
	width: 100%;

	${over('lg')} {
		height: ${({ $isAdm }) =>
			$isAdm ? 'calc(100vh - 230px)' : 'calc(100vh - 270px)'};
		padding-bottom: 50px;
	}
`
