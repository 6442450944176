import { Row } from 'react-bootstrap'
import { styled } from 'styled-components'

export const Wrapper = styled(Row)`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-top: 1.5rem;

	.term {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		padding: 0.5rem 1.2rem;
		border-left: 2px solid ${(props) => props.theme.colours.primary.blue};
		margin-left: 0.5rem;
	}
	.color-grey-shade {
		color: ${(props) => props.theme.colours.primary.greyShade};
	}
	.color-black {
		color: ${(props) => props.theme.colours.primary.black};
	}
	.link-style {
		text-decoration: underline;
		&:hover {
			color: ${(props) => props.theme.colours.primary.blue};
			text-decoration: underline;
		}
	}
	.tag {
		border-radius: 5px;
		padding: 0.2rem 0.6rem;
		font-size: 14px;
		word-break: keep-all;
	}
	.tag-yellow {
		background-color: ${(props) => props.theme.colours.secondary.lightYellow};
		border: 1px solid ${(props) => props.theme.colours.secondary.yellow};
	}

	.light-blue {
		background-color: ${(props) => props.theme.colours.secondary.lightBlue};
		border: 1px solid ${(props) => props.theme.colours.primary.turquise};
	}

	.blue {
		background-color: ${(props) => props.theme.colours.primary.blue};
		border: 1px solid ${(props) => props.theme.colours.primary.blue};
		color: ${(props) => props.theme.colours.primary.white};
	}

	ul {
		padding-left: 2rem;
	}
	li {
		margin-bottom: 0.5rem;
	}
`
