import { LinkSection, SearchSection } from 'components/pages/frontpage'

const MainPage = () => {
	return (
		<>
			<SearchSection />
			<LinkSection />
		</>
	)
}

export default MainPage
