import { SearchBox, SearchProvider, WithSearch } from '@elastic/react-search-ui'
import { generalConfig } from './config'
import { ElasticWrapper, InputView, Heading, Options } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useStoreContext } from 'context/StoreProvider'
import { useEffect, useLayoutEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
	codesystems,
	getCodeSystem,
	getCodeSystemEndpoint,
	getCodeSystemName,
	paths,
} from 'utils'
import { BodyResults, CodesystemTabNav, TabView } from './common'
import { isClinicalCodesystem, isSmallLabCodesystem } from '../utils'
import { searchQuery } from './config/settings'
import type { FilterType, FilterValue } from '@elastic/search-ui'
import { SearchSectionWrapper } from './style'

const SearchWithFilter = () => {
	const { t } = useTranslation()

	const { codeValue } = useParams<{ codeValue: string }>()

	const { state, actions } = useStoreContext()
	const { codeSystem } = state
	const { updateCodeSystem, updateCodeValue } = actions

	const { pathname } = useLocation()

	useEffect(() => {
		const kodeverk = getCodeSystemName(codeSystem as string)
		document.title = `${t('general.finnkode')} - ${kodeverk}`
	}, [codeSystem])

	useLayoutEffect(() => {
		updateCodeSystem(getCodeSystemEndpoint(pathname) as string)
	}, [])

	/* This must exist in a compoent that is parent to other components. 
			Sets the code used for almost all functionality in the app.
			Do not remove. */
	useEffect(() => {
		updateCodeValue(codeValue as string)
	}, [codeValue])

	const handleSetCodesystem = () => {
		if (pathname.includes(paths.norpat)) {
			return codesystems.apat, codesystems.norpat
		} else {
			return getCodeSystem(pathname) as string
		}
	}

	const isAdmOrNorpat =
		pathname.startsWith(paths.adm) || pathname.startsWith(paths.norpat)

	const headerHeight = document.getElementById('site-header')?.clientHeight || 0
	const tabNavHeight =
		document.getElementById('codesystem-tab-nav')?.clientHeight || 42

	const aboveElementsheight = isAdmOrNorpat
		? headerHeight + 1
		: headerHeight + tabNavHeight + 2

	return (
		<>
			{isClinicalCodesystem(pathname) || isSmallLabCodesystem(pathname) ? (
				// Tab navigation between code systems at top of page
				<CodesystemTabNav />
			) : null}

			{/* SearchProvider is a wrapper for the search functionality.
				It encapsulates searchTerm, results, and other elasticsearch data
				in a context that can be access by child components using the withSearch HOC.
				For an example see export statement of TabView component.
			*/}
			<SearchProvider
				config={{
					initialState: {
						searchTerm: '',
						resultsPerPage: isSmallLabCodesystem(pathname) ? 350 : 20,
					},
					searchQuery: {
						filters: [
							{
								field: 'code_system',
								values: [handleSetCodesystem() as FilterValue],
								type: 'any' as FilterType,
							},
							{
								field: 'active',
								values: ['true' as FilterValue],
								type: 'any' as FilterType,
							},
						],
						...searchQuery,
					},
					...generalConfig,
				}}
			>
				<ElasticWrapper className='d-flex flex-column position-relative'>
					<SearchSectionWrapper
						$topValue={aboveElementsheight}
						id='search-wrapper'
						className='pb-2'
					>
						<div className='d-flex gap-3 justify-content-between p-0 pt-2'>
							<label
								id={`${codeSystem}-search-label`}
								htmlFor={`${codeSystem}-search-input`}
							>
								{/* Heading above search bar */}
								<Heading
									variant='h1'
									className='fs-4 fw-bold fs-md-3 mb-2'
									id={`${codeSystem}-page-title`}
								>
									{pathname.includes(paths.norpat)
										? t('norpatPage.title')
										: t('general.searchTitle', {
												term: getCodeSystemName(codeSystem as string),
										  })}{' '}
								</Heading>
							</label>
							<Options /> {/* Flere valg meny */}
						</div>

						{/* Search bar */}
						<SearchBox
							searchAsYouType
							autocompleteResults={false}
							autocompleteSuggestions={false}
							shouldClearFilters={false}
							debounceLength={500}
							inputView={InputView}
						/>
					</SearchSectionWrapper>

					{/* Results (everything under search bar) */}
					{isSmallLabCodesystem(pathname) ? <BodyResults /> : <TabView />}
				</ElasticWrapper>
			</SearchProvider>
		</>
	)
}

export default SearchWithFilter
