import {
	ReverseBooleanFacet,
	ClearFilters,
	CustomFacet,
	CustomFilter,
	FilterTitle,
	SortingView,
} from 'components/common'
import { SidebarFilterWrapper } from 'components/search/buckets/common'
import { Col } from 'react-bootstrap'
import { isDesktop } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

const NLKFilter = () => {
	const { t } = useTranslation()
	return (
		<SidebarFilterWrapper
			$isDesktop={isDesktop}
			className='d-flex flex-column gap-3'
		>
			<div className='pt-3'>
				<FilterTitle
					hasBorderBottom={true}
					className='d-flex justify-content-between'
				>
					{t('nlkPage.filter.title')}
					<ClearFilters />
				</FilterTitle>

				<CustomFacet
					field='active'
					label='Vis inaktive koder'
					filterType='any'
					view={ReverseBooleanFacet}
				/>
			</div>

			<Col className='d-flex flex-column' xs='auto'>
				<SortingView
					label={t('nlkPage.sort.title')}
					sortOptions={[
						{
							name: t('nlkPage.sort.relevance'),
							value: '',
							direction: '',
						},
						{
							name: t('nlkPage.properties.component'),
							value: 'component',
							direction: 'asc',
						},
						{
							name: t('nlkPage.properties.code'),
							value: 'code',
							direction: 'asc',
						},
						{
							name: t('nlkPage.properties.nameNorwegian'),
							value: 'name_norwegian',
							direction: 'asc',
						},
						{
							name: t('nlkPage.properties.propertyType'),
							value: 'property_type',
							direction: 'asc',
						},
						{
							name: t('nlkPage.properties.unit'),
							value: 'unit',
							direction: 'asc',
						},
						{
							name: t('nlkPage.properties.validFrom'),
							value: 'valid_from',
							direction: 'asc',
						},
						{
							name: t('nlkPage.properties.validTo'),
							value: 'valid_to',
							direction: 'asc',
						},
					]}
				/>
			</Col>
			<CustomFacet
				field='subject_area'
				label={t('nlkPage.properties.subjectArea')}
				view={CustomFilter}
				filterType='any'
				isFilterable
			/>
			<CustomFacet
				field='system'
				label={t('nlkPage.properties.system')}
				view={CustomFilter}
				filterType='any'
				isFilterable
			/>
			<CustomFacet
				field='group'
				label={t('nlkPage.properties.group')}
				view={CustomFilter}
				filterType='any'
				isFilterable={true}
			/>
			<CustomFacet
				field='component'
				label={t('nlkPage.properties.component')}
				view={CustomFilter}
				filterType='any'
				isFilterable={true}
			/>
			<CustomFacet
				field='unit'
				label={t('nlkPage.properties.unit')}
				view={CustomFilter}
				filterType='any'
				isFilterable={true}
			/>
		</SidebarFilterWrapper>
	)
}

export default NLKFilter
