import { useRef, useEffect } from 'react'
import type { ClickOutsideContainerProps } from './interface'

const ClickOutsideContainer = ({
	children,
	exceptionRef,
	onClick,
	className,
}: ClickOutsideContainerProps) => {
	const wrapperRef = useRef<HTMLDivElement>(null)

	const handleClickListener = (event: MouseEvent) => {
		let clickedInside
		if (event.offsetX >= document.documentElement.clientWidth) return
		if (exceptionRef) {
			clickedInside =
				(wrapperRef &&
					wrapperRef.current &&
					wrapperRef.current.contains(event.target as Node)) ||
				exceptionRef.current === event.target ||
				exceptionRef.current?.contains(event.target as Node)
		} else {
			clickedInside =
				wrapperRef &&
				wrapperRef.current &&
				wrapperRef.current.contains(event.target as Node)
		}

		if (clickedInside) return
		else onClick()
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickListener)

		return () => {
			document.removeEventListener('mousedown', handleClickListener)
		}
	}, [])

	return (
		<div ref={wrapperRef} className={`${className || ''}`}>
			{children}
		</div>
	)
}

export default ClickOutsideContainer
