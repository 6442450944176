import { paths } from 'utils'

export const clinicalCodework = [
	{
		text: 'codesystems.clinicalCodework.icd10.name',
		link: `${paths.icd10}/chapter`,
		description: 'codesystems.clinicalCodework.icd10.description',
	},
	{
		text: 'codesystems.clinicalCodework.icpc2.name',
		link: `${paths.icpc2}/chapter`,
		description: 'codesystems.clinicalCodework.icpc2.description',
	},
	{
		text: 'codesystems.clinicalCodework.nkpk.name',
		link: `${paths.nkpk}/chapter`,
		description: 'codesystems.clinicalCodework.nkpk.description',
	},
	{
		text: 'codesystems.clinicalCodework.phbu.name',
		link: `${paths.phbu}/chapter`,
		description: 'codesystems.clinicalCodework.phbu.description',
	},
	{
		text: 'codesystems.clinicalCodework.oldVersion.name',
		link: 'https://finnkode.kliniskekodeverk.helsedirektoratet.no',
		description: 'codesystems.clinicalCodework.oldVersion.description',
	},
]

export const labCodework = [
	{
		text: 'codesystems.labCodework.norpat',
		link: paths.norpat,
	},
	{
		text: 'codesystems.labCodework.nlk',
		link: paths.nlk,
	},
	{
		text: 'codesystems.labCodework.provemateriale',
		link: paths.provemateriale,
	},
	{
		text: 'codesystems.labCodework.anatomisk',
		link: paths.anatomisk,
	},
	{
		text: 'codesystems.labCodework.tekstligeresultatverdier',
		link: paths.tekstligeresultatverdier,
	},
	{
		text: 'codesystems.labCodework.undersokelsesmetode',
		link: paths.undersokelsesmetode,
	},
]

export const other = [
	{
		text: 'codesystems.other.standarder.name',
		link: `${paths.adm}/collections`,
		description: 'codesystems.other.standarder.description',
	},
	{
		text: 'codesystems.other.snomedct.name',
		link: `${paths.snomed}/search`,
		description: 'codesystems.other.snomedct.description',
	},
]
