import {
	autocompleteQuery,
	connector,
	searchQuery,
	routingOptions,
} from './settings'

const generalConfig = {
	apiConnector: connector,
	alwaysSearchOnInitialLoad: true,
	hasA11yNotifications: false,
	autocompleteQuery: autocompleteQuery,
	searchQuery: searchQuery,
	routingOptions: routingOptions,
}

export default generalConfig
