import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NodeProps } from './interface'
import { StyledNode } from './style'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const Node = ({
	level,
	isExpanded,
	handleNavigate,
	handleExpand,
	item,
	innerRef,
	isActive,
	children,
}: NodeProps) => {
	const { t } = useTranslation()
	return (
		<StyledNode
			$level={level}
			$isActive={isActive}
			$isExpanded={isExpanded}
			$isLeafNode={item.isLeafNode}
			ref={innerRef}
			id={`${item.codeValue}-node`}
		>
			<div className='d-flex align-items-center gap-2'>
				{!item.isLeafNode && (
					<div
						onClick={handleExpand}
						onKeyDown={(event) => {
							if (event.key === 'Enter' || event.key === ' ') {
								handleExpand()
							}
						}}
						role='button'
						tabIndex={0}
						aria-label={`${t('general.open')} ${item.codeValue} ${
							item.nameNorwegian
						}`}
					>
						<span className='expand-button'>
							{isExpanded ? (
								<FontAwesomeIcon
									icon={faMinus}
									size='sm'
									width={'12px'}
									height={'12px'}
								/>
							) : (
								<FontAwesomeIcon icon={faPlus} size='sm' />
							)}
						</span>
					</div>
				)}
				<span
					className={`single-item text-nowrap ${isActive ? 'active' : ''}`}
					onClick={handleNavigate}
					onKeyDown={(event) => {
						if (event.key === 'Enter' || event.key === ' ') {
							handleNavigate()
						}
					}}
					role='button'
					tabIndex={0}
					aria-label={`${t('general.navigateTo')} ${item.codeValue} ${
						item.nameNorwegian
					}`}
				>
					<span className='code'>{item.codeValue}</span>
					<span className='term'>{item.nameNorwegian}</span>
				</span>
			</div>
			{children}
		</StyledNode>
	)
}

export default Node
