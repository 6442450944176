import { styled } from 'styled-components'

export const Wrapper = styled.div`
	position: relative;
	.scroll-to-top {
		position: fixed;
		bottom: 30px;
		right: 30px;
		z-index: 999;
		cursor: pointer;
	}
`
