import { SearchBox, SearchProvider, WithSearch } from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import { snomedConfigOptions } from './config/snomedConfigOptions'
import { ElasticWrapper, Heading, InputView, Options } from 'components/common'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useStoreContext } from 'context/StoreProvider'
import { Col } from 'react-bootstrap'
import TabView from './common/TabView'
import { useTranslation } from 'react-i18next'
import { resultsPerPage } from 'utils'
import { SearchSectionWrapper } from '../SearchWithFilter/style'

const SnomedSearch = () => {
	const { codeValue } = useParams()
	const { t } = useTranslation()
	const { actions } = useStoreContext()
	const { updateCodeValue } = actions

	useEffect(() => {
		document.title = `${t('general.finnkode')} - ${t('snomedctPage.name')}`
	}, [])

	useEffect(() => {
		updateCodeValue(codeValue)
	}, [codeValue, updateCodeValue])

	const headerHeight = document.getElementById('site-header')?.clientHeight || 0

	return (
		<SearchProvider
			config={{
				...snomedConfigOptions,
				initialState: {
					resultsPerPage: resultsPerPage,
				},
			}}
		>
			<WithSearch
				mapContextToProps={({
					searchTerm,
					isFetching,
					resultSearchTerm,
					isLoading,
					results,
				}) => ({
					searchTerm,
					isFetching,
					resultSearchTerm,
					isLoading,
					results,
				})}
			>
				{() => {
					return (
						<ElasticWrapper className='d-flex flex-column position-relative'>
							<SearchSectionWrapper
								$topValue={headerHeight}
								className='d-flex justify-content-between gap-2 pt-2'
								id='snomed-search-wrapper'
							>
								<Col className='d-flex flex-column p-0'>
									<label id='downshift-1-label' htmlFor='search-input'>
										<Heading
											variant='h1'
											className='fs-4 fw-bold fs-md-3 mb-2'
											id='snomed-ct-page-title'
										>
											{t('snomedctPage.title')}
										</Heading>
									</label>
									<SearchBox
										searchAsYouType
										autocompleteResults={false}
										autocompleteSuggestions={false}
										shouldClearFilters={false}
										debounceLength={500}
										inputView={InputView}
									/>
								</Col>
								<Options />
							</SearchSectionWrapper>

							<TabView />
						</ElasticWrapper>
					)
				}}
			</WithSearch>
		</SearchProvider>
	)
}

export default SnomedSearch
